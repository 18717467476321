import KeyCloakService from '@/service/authentication/KeyCloakService';
import {
  confirmCustomerExistsUsingGET,
  createCustomerUsingPOST,
  deleteCustomerUsingPOST,
  getMyCustomersUsingGET,
  updateCustomerUsingPUT,
  validateCustomerNameUsingGET
} from '@/clients/wbBack.api';
import {authHeaders} from '@/clients/headers/authHeader';
import router from '@/router';
import {showAlertBaseOnResponseAndCode} from '@/util/alert/showAlertBaseOnResponseAndCode';
import i18n from '@/i18n';

class CustomerService {
  async getAllCustomers() {
    try {
      let token = await KeyCloakService.getAuthToken();
      let license = await getMyCustomersUsingGET({
        $config: authHeaders(token)
      });

      if (license?.status === undefined && license?.response?.status === undefined) {
        await router.push('/something-went-wrong');
      }
      return license?.data;
    } catch {
      return [];
    }
  }

  async updateCustomer(customerName, customerUpdate) {
    try {
      let token = await KeyCloakService.getAuthToken();
      let customer = await updateCustomerUsingPUT({
        customerName,
        customerUpdate,
        $config: authHeaders(token)
      });


      await showAlertBaseOnResponseAndCode(customer, {
        successMessage: i18n.t('services.successMessages.CUSTOMER_IS_SUCCESSFULLY_UPDATED', {customerName}),
        errorMessage: i18n.t('services.errorMessages.CANNOT_UPDATE_CUSTOMER', {customerName}),
      });
    } catch {
      // do nothing
    }
  }

  async createCustomer(customer) {
    try {
      let token = await KeyCloakService.getAuthToken();
      let createdCustomer = await createCustomerUsingPOST({
        customer,
        $config: authHeaders(token)
      });

      await showAlertBaseOnResponseAndCode(createdCustomer, {
        successMessage: i18n.t('services.successMessages.CUSTOMER_WAS_CREATED_SUCCESSFULLY'),
        errorMessage: i18n.t('services.errorMessages.CUSTOMER_WAS_NOT_CREATED'),
      });
    } catch {
      // do nothing
    }
  }

  async isDomainValid(customerName) {
    try {
      let token = await KeyCloakService.getAuthToken();
      let isValid = await validateCustomerNameUsingGET({
        customerName,
        $config: authHeaders(token)
      });

      return isValid?.status === 200;
    } catch {
      // do nothing
    }
  }

  async doesCustomerExist(customerName) {
    try {
      let token = await KeyCloakService.getAuthToken();
      let customerExist = await confirmCustomerExistsUsingGET({
        customerName,
        $config: authHeaders(token)
      });

      return customerExist?.data;
    } catch {
      // do nothing
    }
  }

  async confirmUserDeletion(customerName) {
    try {
      let token = await KeyCloakService.getAuthToken();
      let deletedCustomer = await deleteCustomerUsingPOST({
        customerName,
        $config: authHeaders(token)
      });

      await showAlertBaseOnResponseAndCode(deletedCustomer, {
        successMessage: i18n.t('services.successMessages.CUSTOMER_WAS_DELETED_SUCCESSFULLY'),
        errorMessage: i18n.t('services.errorMessages.CANNOT_DELETE_CUSTOMER'),
        conflictMessage: i18n.t('services.errorMessages.CANNOT_DELETE_CUSTOMER'),
        badRequestMessage: i18n.t('services.errorMessages.CANNOT_DELETE_CUSTOMER'),
        notFoundMessage: i18n.t('services.errorMessages.CANNOT_DELETE_CUSTOMER'),
      });
    } catch {
      // do nothing
    }
  }

}


export default new CustomerService();
