<template>
  <div>
    <div class="text-center">
      <v-dialog
          v-model="openDeleteManagerDialog"
          width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon color="primary">mdi-delete</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 primary white--text">
            {{ $t('reseller.deleteManagerDialog.deleteManagerTitle') }}
          </v-card-title>

          <v-card-text class="pa-4 primary--text ">
            {{ manager.firstName + ' ' + manager.lastName }}
          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <div>
              <v-btn
                  color="primary"
                  class="elevation-0 text-none"
                  @click="openDeleteManagerDialog = false"
              >
                {{ $t('reseller.deleteManagerDialog.cancel') }}
              </v-btn>
              <v-btn
                  color="error"
                  class="elevation-0 text-none ml-4"
                  @click="deleteManager"
              >
                {{ $t('reseller.deleteManagerDialog.delete') }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'ConfirmDeleteManagerDialog',
  props: {
    manager: {
      type: Object
    }
  },
  data() {
    return {
      openDeleteManagerDialog: false
    };
  },
  methods: {
    ...mapActions('reseller', ['deleteManagerUser']),
    deleteManager() {
      this.openDeleteManagerDialog = false;
      this.deleteManagerUser({whistleblowerManager: this.manager});
    }
  }
};
</script>

<style scoped>

</style>
