import Profile from '@/views/private/reseller/others/Profile';
import EditCustomer from '@/views/private/reseller/customer/EditCustomer';
import ActiveCustomers from '@/views/private/reseller/customer/ActiveCustomers';
import HasOpenCases from '@/views/private/reseller/customer/HasOpenCases';
import {accountRoles} from '@/models/others/accountRoles';
import {validatePrivateRoute} from '@/router/guards/authGuards';
import EditEmailInfo from '@/views/private/reseller/settings/EditEmailInfo';
import DeleteCustomer from '@/views/private/reseller/customer/DeleteCustomer';
import {customerMenu, editCustomerMenu, settingsMenu} from '@/models/reseller/resellerNavigation';

export const resellerPages = [
  // customers
  {
    path: '/reseller/customer/active',
    name: 'ActiveCustomers',
    component: ActiveCustomers,
    beforeEnter: validatePrivateRoute,
    meta: {
      role: accountRoles.RESELLER_MANAGER,
      navigationMenuSubItems: customerMenu,
    }
  },
  {
    path: '/reseller/customer/open-cases',
    name: 'HasOpenCases',
    component: HasOpenCases,
    beforeEnter: validatePrivateRoute,
    meta: {
      role: accountRoles.RESELLER_MANAGER,
      navigationMenuSubItems: '',
    }
  },
  {
    path: '/reseller/customer/edit',
    name: 'EditCustomer',
    component: EditCustomer,
    beforeEnter: validatePrivateRoute,
    meta: {
      role: accountRoles.RESELLER_MANAGER,
      navigationMenuSubItems: editCustomerMenu,
    }
  },
  {
    path: '/reseller/customer/delete',
    name: 'DeleteCustomer',
    component: DeleteCustomer,
    beforeEnter: validatePrivateRoute,
    meta: {
      role: accountRoles.RESELLER_MANAGER,
      navigationMenuSubItems: editCustomerMenu,
    }
  },
  {
    path: '/reseller/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: validatePrivateRoute,
    meta: {
      role: accountRoles.RESELLER_MANAGER,
      navigationMenuSubItems: customerMenu,
    }
  },
  {
    path: '/reseller/settings/edit-email',
    name: 'EditEmailInfo',
    component: EditEmailInfo,
    beforeEnter: validatePrivateRoute,
    meta: {
      role: accountRoles.RESELLER_MANAGER,
      navigationMenuSubItems: settingsMenu,
    }
  },
];
