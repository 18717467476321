import Vue from 'vue';
import Vuex from 'vuex';
import {navigation} from '@/store/modules/navigation.module';
import {event} from '@/store/modules/event.module';
import {reseller} from '@/store/modules/reseller.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {navigation, event, reseller}
});

