<template>
  <v-app id="inspire">
    <div style="height: 50%; width: 100%" class="d-flex justify-center align-center">
      <div>
        <div class="d-flex justify-center align-center primary--text">
          You are in the wrong place…
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'WrongPlacePage',
};
</script>

<style scoped>

</style>
