// Imports
import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from '@/views/public/Dashboard';
import NotFound from '@/views/public/NotFound';
import privateRoutes from './privateRoutes';
import Login from '@/views/public/Login';
import RedirectToPage from '@/views/public/RedirectToPage';
import {accountRoles} from '@/models/others/accountRoles';
import {canAccessThePage} from '@/interceptors/role.interceptor';
import SomethingWentWrong from '@/views/public/SomethingWentWrong';
import WrongPlacePage from '@/views/public/WrongPlacePage';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return {selector: to.hash};
    if (savedPosition) return savedPosition;
    return {x: 0, y: 0};
  },
  routes: [
    {
      path: '',
      name: 'Dashboard',
      component: Dashboard,
      children: privateRoutes,
      meta: {
        role: accountRoles.GENERAL_PAGE
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        role: accountRoles.GENERAL_PAGE
      }
    },
    {
      path: '/redirect',
      name: 'Redirect',
      component: RedirectToPage,
      meta: {
        role: accountRoles.GENERAL_PAGE
      }
    },
    {
      path: '/something-went-wrong',
      name: 'SomethingWentWrong',
      component: SomethingWentWrong,
      meta: {
        role: accountRoles.GENERAL_PAGE
      }
    },
    {
      path: '/wrong-place',
      name: 'WrongPlacePage',
      component: WrongPlacePage,
      meta: {
        role: accountRoles.GENERAL_PAGE
      }
    },
    {
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: NotFound,
      meta: {
        authenticated: false,
        role: accountRoles.GENERAL_PAGE
      }
    }
  ],
});


router.beforeEach(async (to, from, next) => {
  const role = to.meta.role;
  await canAccessThePage(role, next);
});


export default router;
