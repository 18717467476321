import {getSupportedLicenseUsingGET, selectLicenseUsingPUT} from '@/clients/wbBack.api';
import {authHeaders} from '@/clients/headers/authHeader';
import KeyCloakService from '@/service/authentication/KeyCloakService';
import {showAlertBaseOnResponseAndCode} from '@/util/alert/showAlertBaseOnResponseAndCode';
import i18n from '@/i18n';

class LicenseService {
  async getLicenseForCustomer() {
    try {
      let token = await KeyCloakService.getAuthToken();
      let license = await getSupportedLicenseUsingGET({
        $config: authHeaders(token)
      });
      return license?.data;
    } catch {
      // do nothing
    }
  }

  async updateCustomerLicense(customerName, licenseSelection) {
    try {
      let token = await KeyCloakService.getAuthToken();
      let license = await selectLicenseUsingPUT({
        customerName,
        licenseSelection,
        $config: authHeaders(token)
      });

      await showAlertBaseOnResponseAndCode(license, {
        successMessage: i18n.t('services.successMessages.LICENSE_FOR_CUSTOMER_IS_UPDATED', {customerName}),
        errorMessage: i18n.t('services.errorMessages.CANNOT_UPDATE_LICENSE_FOR_CUSTOMER', {customerName}),
      });
    } catch {
      // do nothing
    }
  }
}

export default new LicenseService();
