import KeyCloakService from '@/service/authentication/KeyCloakService';
import router from '@/router';
import store from '@/store';
import {isFalsy} from '@/util/guards';
import {accountRoles} from '@/models/others/accountRoles';
import {resellerNavigation} from '@/models/reseller/resellerNavigation';

export const redirectToUserPageBaseOnRole = async () => {
  let instance = await KeyCloakService.keyCloakInstance();

  let roles = instance?.realmAccess?.roles;

  if (isFalsy(roles)) {
    await KeyCloakService.loginUser();
  }

  if (roles.includes(accountRoles.RESELLER_MANAGER)) {
    await router.push('/reseller/customer/active');
    await store.commit('navigation/setSelectedNavigation', resellerNavigation, {root: true});
    await store.commit('navigation/setSelectedSubPages', resellerNavigation.topBar[0].subMenu, {root: true});
  } else if (
    roles.includes(accountRoles.ASSISTANT_INVESTIGATION_OFFICER) ||
    roles.includes(accountRoles.PROTECTION_OFFICER) ||
    roles.includes(accountRoles.INVESTIGATION_OFFICER) ||
    roles.includes(accountRoles.WHISTLEBLOWER) ||
    roles.includes(accountRoles.WHISTLEBLOWER_MANAGER)
  ) {
    await router.push('/wrong-place');
  } else {
    await router.push('/login');
  }
};


export const canAccessThePage = async (role, next) => {
  let instance = await KeyCloakService.keyCloakInstance();

  let roles = instance?.realmAccess?.roles;

  if (isFalsy(roles)) {
    await KeyCloakService.loginUser();
  }

  if (role === accountRoles.GENERAL_PAGE) {
    next();
  } else {
    if (roles.includes(role)) {
      next();
    } else {
      next('/redirect');
    }
  }
};
