<template>
  <div class="pa-16">
    <div class="d-flex justify-center">
      <span class="primary--text font-size-24">{{ $t('commons.technicalIssue.title') }}</span>
    </div>
    <div class="d-flex justify-center mt-6">
      <span class="primary--text font-size-24">{{ $t('commons.technicalIssue.workingOnIt') }}</span>
    </div>

    <IllustrationSmthWentWrong/>
  </div>
</template>

<script>
import IllustrationSmthWentWrong from '@/components/illustration/IllustrationSmthWentWrong';

export default {
  name: 'SomethingWentWrong',
  components: {IllustrationSmthWentWrong}
};
</script>

<style scoped>

</style>
