import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {da} from '@/language/da/da';


Vue.use(VueI18n);

function loadLocaleMessages() {
  return {
    da
  };
}


export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'da',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'da',
  messages: loadLocaleMessages()
});
