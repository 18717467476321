import UserService from '@/service/reseller/UserService';
import LicenseService from '@/service/reseller/LicenseService';
import CustomerService from '@/service/reseller/CustomerService';
import {isTruthy} from '@/util/guards';
import router from '@/router';
import {resellerNavigation} from '@/models/reseller/resellerNavigation';

const state = {
  users: [],
  license: null,

  subsidiaryCompanies: [],
  isManagerUsernameValid: false,

  allCustomers: [],
  selectedCustomer: null,

  resellerEmail: null,

  doesCustomerExist: false,
};

const getters = {
  getLicense: state => state.license,

  getIsManagerUsernameValid: state => state.isManagerUsernameValid,

  getAllCustomers: state => state.allCustomers,
  getSelectedCustomer: state => state.selectedCustomer,

  getDoesCustomerExist: state => state.doesCustomerExist,

  getResellerEmail: state => state.resellerEmail,
};

const actions = {
  async retrieveAllUsers({commit}) {
    let allUsers = await UserService.getAllUsers();
    commit('setUsers', allUsers);
  },

  async createUser({dispatch}, {createUserModel}) {
    await UserService.createManagerUser(createUserModel);
    await dispatch('retrieveAllUsers');
  },

  async retrieveLicence({commit}) {
    let license = await LicenseService.getLicenseForCustomer();
    commit('setLicence', license);
  },

  async updateCustomerLicense({state, dispatch}, {license}) {
    await LicenseService.updateCustomerLicense(state.selectedCustomer.name, license);
    await dispatch('refreshCustomerSelected');
  },

  async retrieveAllCustomers({commit}) {
    let allCustomers = await CustomerService.getAllCustomers();
    commit('setAllCustomers', allCustomers);
  },

  async createCustomer({dispatch}, {customer}) {
    await CustomerService.createCustomer(customer);
    await dispatch('retrieveAllCustomers');
  },

  // eslint-disable-next-line no-unused-vars
  async isDomainValid({dispatch}, {customerName}) {
    return await CustomerService.isDomainValid(customerName);
  },

  async validateCustomerNameExistence({commit}, {customerName}) {
    let customerExist = await CustomerService.doesCustomerExist(customerName);
    commit('setDoesCustomerExist', customerExist);
  },

  async confirmUserDeletion({dispatch, commit}, {customerName}) {
    await CustomerService.confirmUserDeletion(customerName);
    await dispatch('retrieveAllCustomers');
    await commit('navigation/setSelectedNavigation', resellerNavigation, {root: true});
    await commit('navigation/setSelectedSubPages', resellerNavigation.topBar[0].subMenu, {root: true});
    await commit('setSelectedCustomer', null);
    await router.push('/reseller/customer/active');
  },

  async updateCustomerSelected({dispatch, state}, {customer}) {
    await CustomerService.updateCustomer(state.selectedCustomer.name, customer);
    await dispatch('refreshCustomerSelected');
  },

  async refreshCustomerSelected({commit, state}) {
    let allCustomers = await CustomerService.getAllCustomers();
    await commit('setAllCustomers', allCustomers);

    let selectedCustomer = allCustomers.find(el => el.name === state.selectedCustomer.name);
    if (isTruthy(selectedCustomer)) {
      await commit('setSelectedCustomer', selectedCustomer);
    }
  },

  async addManagerToCompany({state, dispatch}, {manager}) {
    await UserService.createManagerUser(state.selectedCustomer.name, manager);
    await dispatch('refreshCustomerSelected');
  },

  async requestPasswordUpdate() {
    await UserService.requestPasswordUpdate();
  },

  async validateUserName({commit}, {username}) {
    let isValid = await UserService.validateShortFormatUserName(username);
    commit('setIsManagerUsernameValid', isValid);
  },

  async updateManagerUser({dispatch, state}, {manager}) {
    await UserService.updateManagerUser(state.selectedCustomer.name, manager);
    dispatch('refreshCustomerSelected');
  },

  async deleteManagerUser({dispatch, state}, {whistleblowerManager}) {
    await UserService.deleteManagerUser(state.selectedCustomer.name, whistleblowerManager);
    dispatch('refreshCustomerSelected');
  },

  async retrieveResellerEmail({commit}) {
    let resellerEmail = await UserService.retrieveResellerEmail();
    commit('setResellerEmail', resellerEmail);
  },

  // eslint-disable-next-line no-unused-vars
  async updateResellerEmail({dispatch}, {resellerEmailDto}) {
    await UserService.updateResellerEmail(resellerEmailDto);
  },
};

const mutations = {
  setUsers: (state, users) => state.users = users,

  setAllCustomers: (state, allCustomers) => state.allCustomers = allCustomers,
  setSelectedCustomer: (state, selectedCustomer) => state.selectedCustomer = selectedCustomer,

  setIsManagerUsernameValid: (state, isManagerUsernameValid) => state.isManagerUsernameValid = isManagerUsernameValid,

  setLicence: (state, licence) => state.license = licence,
  setResellerEmail: (state, resellerEmail) => state.resellerEmail = resellerEmail,

  setDoesCustomerExist: (state, customerExist) => state.doesCustomerExist = customerExist,
};

export const reseller = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

