<template>
  <div>
    <PageHeader
        class="mr-10"
        :header-title="$t('commons.editCustomer.company') + ' ' + getSelectedCustomer.friendlyName"
        :header-subtitle="$t('commons.editCustomer.domain') + ' ' + getSelectedCustomer.name"
    />

    <div>
      <ActionCard :title="$t('reseller.deleteCustomer.deleteCustomer')">
        <div slot="actionFields">
          <div>
            <label class="default-text">{{ $t('reseller.deleteCustomer.toDeleteCustomerWarningText') }}</label>
            <v-text-field
                v-model="customerName"
                class="mt-2"
                outlined
                autocomplete="off"
                @paste="preventPaste"
            />
            <span v-if="showCustomerDoesNotExistMessage" class="error--text">
              {{ $t('reseller.deleteCustomer.customerDomainNameIsNotRight') }}
            </span>
          </div>
        </div>
        <div slot="actionBtn" class="d-flex justify-end w-100-p">
          <v-btn width="150" :disabled="!isTypingRightCustomerName" class="text-none" color="primary"
                 @click="validateCustomerName"
          >
            {{ $t('reseller.deleteCustomer.delete') }}
          </v-btn>
        </div>
      </ActionCard>
    </div>


    <div v-if="getDoesCustomerExist">
      <ActionCard color="warning" :title="$t('reseller.deleteCustomer.confirmDeletion')">
        <div slot="actionFields">
          <div>
            <span class="default-text">{{ $t('reseller.deleteCustomer.areYouSure1') }}</span>
            <br><br>
            <span class="default-text">{{ $t('reseller.deleteCustomer.areYouSure2') }}</span>
            <br><br>
            <span class="default-text">{{ $t('reseller.deleteCustomer.areYouSure3') }}</span>
          </div>
        </div>
        <div slot="actionBtn" class="d-flex justify-center w-100-p mt-6">
          <v-btn width="150"
                 class="mr-4 black--text text-none"
                 color="lightPrimary"
                 @click="cancelUserDeletion"
          >
            {{ $t('reseller.deleteCustomer.no') }}
          </v-btn>

          <v-btn width="150" class="ml-4 text-none" color="error" @click="confirmDeletion">
            {{ $t('reseller.deleteCustomer.yes') }}
          </v-btn>
        </div>
      </ActionCard>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/header/PageHeader';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import ActionCard from '@/components/cards/ActionCard';

export default {
  name: 'DeleteCustomer',
  components: {ActionCard, PageHeader},
  data() {
    return {
      customerName: null,
      showErrorMessage: false
    };
  },
  computed: {
    ...mapGetters('reseller', ['getSelectedCustomer', 'getDoesCustomerExist']),
    isTypingRightCustomerName() {
      return this.getSelectedCustomer.name === this.customerName;
    },
    showCustomerDoesNotExistMessage() {
      return !this.getDoesCustomerExist && this.showErrorMessage;
    }
  },
  methods: {
    ...mapActions('reseller', ['validateCustomerNameExistence', 'confirmUserDeletion']),
    ...mapMutations('reseller', ['setDoesCustomerExist']),
    validateCustomerName() {
      this.validateCustomerNameExistence({customerName: this.customerName}).then(() => {
        this.showErrorMessage = true;
      });
    },
    cancelUserDeletion() {
      this.resetState();
    },
    confirmDeletion() {
      this.confirmUserDeletion({customerName: this.customerName});
    },
    preventPaste(evt) {
      evt.preventDefault();
    },
    resetState() {
      this.setDoesCustomerExist(false);
      this.customerName = null;
      this.showErrorMessage = false;
    }
  },
  beforeDestroy() {
    this.resetState();
  }
};
</script>

<style scoped>

</style>
