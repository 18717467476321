export const commons = {
  licenseCard: {
    companyLicense: 'Company License',
    selectLicense: 'Vælg licensmodel',
    updateLicense: 'Opdater licensmodel',
    licenseType: 'Licens type',
    corporate: 'Organisation',
    yes: 'Ja',
    no: 'Nej',
    numberAIOs: 'Assistenter',
    numberIOs: 'Sagsansvarlige',
    numberPOs: 'Modtagerpersoner',
    numberOfSubsidiaries: 'Datterselskaber skal kunne fastsættes',
  },
  manager: {
    title: 'Manager',
    createManager: 'Opret manager',
    firstName: 'Fornavn',
    lastName: 'Efternavn',
    email: 'Email',
    phone: 'Telefon',
    username: 'Brugernavn',
    usernameIsValid: 'Brugernavn {username} er gyldigt',
    usernameIsNotValid: 'Brugernavn er ikke gyldigt. Brug format: \'ma-xxx@xxxx.xx\'',
    update: 'Opdater',
    save: 'Gem',
  },
  createCustomer: {
    openCustomer: 'Opret ny kunde',
    createCustomer: 'Opret ny kunde',
    name: 'Navn på kunde',
    domain: 'Domæne',
    isValid: 'Indtastning er gyldigt',
    createButton: 'Opret kunde',
  },
  list: {
    organisation: 'Organisation',
    licenseModel: 'Licens model',
    domain: 'Domæne',
    created: 'Oprettet',
    actions: 'Handling',
    edit: 'Rediger',
  },
  input: {
    fieldRequired: '* Felt påkrævet',
    email: 'Skal være en gyldig email adresse',
    validPhoneNumber: '* indtast gyldigt telefonnummer',
    phoneNr: 'Mobilnummer',
    examplePhoneNr: 'Eksempel mobilnummer',
  },
  editCustomer: {
    company: 'Virksomhed',
    domain: 'Domæne',
    friendlyName: 'Navn',
    updateName: 'Rediger navn',
  },
  notFound: {
    title: 'Siden ikke fundet',
    takeMe: 'Gå til',
    home: 'hjem',
  },
  technicalIssue: {
    title: 'Tekniske problemer',
    workingOnIt: 'Arbejder på sagen',
  },
  logOut: 'Log ud',
  logIn: 'Log ind',
};
