import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import store from '@/store';
import router from '@/router';
import i18n from '@/i18n';
import ResellerFront from '@/ResellerFront.vue';

export const registerKeyClockWithVueApplication = (keycloakInstance) => {
  keycloakInstance?.init({checkLoginIframe: false}).then(() => {
    new Vue({
      vuetify,
      store,
      router,
      i18n,
      render: h => h(ResellerFront)
    }).$mount('#app');
  }).catch(() => {
    new Vue({
      vuetify,
      store,
      router,
      i18n,
      render: h => h(ResellerFront)
    }).$mount('#app');
  });

};
