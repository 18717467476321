export const reseller = {
  nav: {
    customerInfo: 'Kundeoplysninger',
    customerList: 'Kundeliste',
    activeCustomer: 'Aktive kunder',
    editCustomer: 'Rediger kunde',
    deleteCustomer: 'Slet kunde',
    systemMail: 'System email',
    editEmailInfo: 'Email oplysninger',
  },
  editEmailInfo: {
    updateEmail: 'Opdater email oplysninger for system emails',
    email: 'Email',
    friendlyEmailName: 'Email display navn',
    save: 'Gem',
  },
  deleteCustomer: {
    toDeleteCustomerWarningText: 'For at slette kunden, skal du indtaste det fulde domænenavn for kunden:',
    delete: 'Slet',
    no: 'Nej',
    yes: 'Ja',
    confirmDeletion: 'Bekræft sletning',
    areYouSure1: 'Du er ved at slette en kunde. Du sletter samtidig alle kundens sager i systemet.',
    areYouSure2: 'Det er ikke muligt at genskabe data efter sletning. Du skal derfor være helt sikker på, at du vil' +
      ' slette kunden, inden du gennemfører handlingen.',
    areYouSure3: 'Ønsker du fortsat at slette kunden?',
    deleteCustomer: 'Slet kunde',
    customerDomainNameIsNotRight: '* ugyldigt kundedomænenavn',
  },
  licenseModelTypes: {
    BASIC: 'BASIS',
    EXTENDED: 'UDVIDET',
    TAILOR: 'TAILOR',
  },
  deleteManagerDialog: {
    deleteManagerTitle: 'Vil du slette denne bruger?',
    cancel: 'Nej',
    delete: 'Ja',
  }
};
