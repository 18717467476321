<template>
  <v-app id="inspire">
    <v-overlay :value="getIsLoading" style="z-index: 99999">
      <v-progress-circular
          indeterminate
          size="64"
      />
    </v-overlay>

    <router-view/>


    <h1 class="d-none">Version: {{ getPackageVersion }}</h1>
  </v-app>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex';
import ApplicationVersionStorage from '@/provider/ApplicationVersionStorage';

export default {
  name: 'ResellerFront',
  computed: {
    ...mapGetters('event', ['getIsLoading']),
    getPackageVersion() {
      return ApplicationVersionStorage.getAppVersion();
    }

  },
  watch: {
    '$route': {
      handler() {
        this.setSelectedSubPages(this.$route.meta?.navigationMenuSubItems?.subMenu);
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations('navigation', ['setSelectedSubPages'])
  }
};
</script>

<style lang="scss" src="./assets/styles/main.scss"/>

