import i18n from '@/i18n';

export const editCustomerMenu = {
  title: i18n.t('reseller.nav.customerInfo'),
  to: '/reseller/customer/active',
  showTopBar: false,
  subMenu: [
    {
      title: i18n.t('reseller.nav.editCustomer'),
      icon: 'mdi-chart-timeline-variant',
      to: '/reseller/customer/edit',
    },
    {
      title: i18n.t('reseller.nav.deleteCustomer'),
      icon: 'mdi-delete',
      to: '/reseller/customer/delete',
    }
  ],
};

export const customerMenu = {
  title: i18n.t('reseller.nav.customerList'),
  to: '/reseller/customer/active',
  showTopBar: true,
  subMenu: [
    {
      title: i18n.t('reseller.nav.activeCustomer'),
      icon: 'mdi-chart-timeline-variant',
      to: '/reseller/customer/active',
    },
  ],
};

export const settingsMenu = {
  title: i18n.t('reseller.nav.systemMail'),
  to: '/reseller/settings/edit-email',
  showTopBar: true,
  subMenu: [
    {
      title: i18n.t('reseller.nav.editEmailInfo'),
      icon: 'mdi-pencil',
      to: '/reseller/settings/edit-email',
    }
  ],
};

export const resellerNavigation = {
  title: 'Reseller',
  to: '/reseller',
  role: 'RESELLER_MANAGER',
  topBar: [
    {...customerMenu},
    {...settingsMenu},
  ],
};
