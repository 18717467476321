import Vue from 'vue';
import {frontEndUrl} from '@/clients/baseUrl';

class KeyCloakService {
  async loginUser() {
    return Vue.$keycloak.login({redirectUri: frontEndUrl + '/redirect'});
  }

  async logoutUser() {
    await Vue.$keycloak.logout({
      redirectUri: frontEndUrl + '/login'
    });
  }

  async isUserAuthenticated() {
    return Vue.$keycloak.authenticated;
  }

  async getAuthToken() {
    return await Vue.$keycloak.token;
  }

  async refreshToken() {
    try {
      await Vue.$keycloak.updateToken(70);
    } catch {
      //
    }
  }

  async keyCloakInstance() {
    return await Vue.$keycloak;
  }

  async getUserInformationFromKeycloak() {
    let instance = await Vue.$keycloak;
    return instance.loadUserInfo();
  }
}

export default new KeyCloakService();
