<template>
  <div class="mt-6">
    <v-card elevation="2">
      <v-card elevation="1" :color="color" class="rounded-b-0 card-inner-padding pb-1 pt-1">
        <v-card-title class="text-sm-h6 white--text pl-0">{{ title }}</v-card-title>
      </v-card>
      <div class="card-inner-padding">
        <div v-if="isTruthy(desc)" class="mb-10">
          <span class="font-size-18">{{ desc }}</span>
        </div>

        <div>
          <slot name="actionFields"/>
        </div>

        <div class="d-flex justify-space-between align-center w-100-p">
          <slot name="actionBtn"/>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {isTruthy} from '@/util/guards';

export default {
  name: 'ActionCard',
  props:{
    title:{
      type: String,
      default: ''
    },
    desc:{
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  methods:{
    isTruthy
  }
};
</script>

<style scoped>

</style>
