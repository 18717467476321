import Vue from 'vue';
import '@/assets/styles/overrides.sass';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import '@/plugins/keycloak';

import vue_moment from 'vue-moment';
import Toasted from 'vue-toasted';

import {setDomain} from '@/clients/wbBack.api';
import {axiosAuthenticationInterceptor, axiosAuthorizationInterceptor} from '@/interceptors/auth.interceptor';
import ConfigurationService from '@/service/ConfigurationService';


Vue.component('vue-phone-number-input', VuePhoneNumberInput);


Vue.use(Toasted);
Vue.use(vue_moment);
Vue.config.productionTip = false;

ConfigurationService.getBackendUrlFromConfiguration().then(domain => {
  setDomain(domain);
  axiosAuthenticationInterceptor();
  axiosAuthorizationInterceptor();
});

