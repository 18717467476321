<template>
  <v-app id="inspire">
    <div style="height: 100%; width: 100%" class="d-flex justify-center align-center">
      <div>Redirecting</div>
    </div>
  </v-app>
</template>

<script>
import {redirectToUserPageBaseOnRole} from '@/interceptors/role.interceptor';

export default {
  name: 'RedirectToPage',
  async mounted() {
    await redirectToUserPageBaseOnRole();
  }
};
</script>

<style scoped>

</style>
