export const companyName = window.location.hostname.split('.')[0];
export const domainName = window.location.hostname.split('.').slice(1).join('.');

export const backendUrl =
  process.env.VUE_APP_BACK_END_PROTO +
  process.env.VUE_APP_BACK_END_URL +
  process.env.VUE_APP_BACK_END_PORT;


let {protocol, host} = window.location;
export const frontEndUrl = `${protocol}//${host}`;






