import i18n from '@/i18n';
import KeyCloakService from '@/service/authentication/KeyCloakService';
import {
  addWhistleblowerManagerToCustomerUsingPOST,
  deleteWhistleblowerManagerUsingDELETE, deleteWhistleblowerManagerUsingPOST,
  getResellerInformationUsingGET,
  getUsersUsingGET,
  requestPasswordUpdateForResellerManagerUsingPUT,
  updateEmailUsingPOST,
  updateWhistleblowerManagerUsingPOST,
  validateCustomerAdminUsernameUsingPOST,
} from '@/clients/wbBack.api';
import {authHeaders} from '@/clients/headers/authHeader';
import {showAlertBaseOnResponseAndCode} from '@/util/alert/showAlertBaseOnResponseAndCode';

class UserService {
  async getAllUsers() {
    try {
      let token = await KeyCloakService.getAuthToken();
      let allUsers = await getUsersUsingGET({
        $config: authHeaders(token)
      });
      return allUsers?.data;
    } catch {
      return [];
    }
  }

  async createManagerUser(customerName, whistleblowerManager) {
    try {
      let token = await KeyCloakService.getAuthToken();
      let createdManager = await addWhistleblowerManagerToCustomerUsingPOST({
        customerName,
        whistleblowerManager,
        $config: authHeaders(token)
      });

      await showAlertBaseOnResponseAndCode(createdManager, {
        successMessage: i18n.t('services.successMessages.MANAGER_IS_CREATED', {customerName}),
        errorMessage: i18n.t('services.errorMessages.CANNOT_CREATE_MANAGER', {customerName}),
      });
    } catch {
      // do nothing
    }
  }

  async updateManagerUser(customerName, whistleblowerManager) {
    try {
      let token = await KeyCloakService.getAuthToken();
      let updateManager = await updateWhistleblowerManagerUsingPOST({
        customerName,
        whistleblowerManager,
        $config: authHeaders(token)
      });

      await showAlertBaseOnResponseAndCode(updateManager, {
        successMessage: i18n.t('services.successMessages.MANAGER_IS_UPDATED', {customerName}),
        errorMessage: i18n.t('services.errorMessages.CANNOT_UPDATE_MANAGER', {customerName}),
      });
    } catch {
      // do nothing
    }
  }

  async deleteManagerUser(customerName, whistleblowerManager) {
    try {
      let token = await KeyCloakService.getAuthToken();
      let deleteManager = await deleteWhistleblowerManagerUsingPOST({
        customerName,
        whistleblowerManager,
        $config: authHeaders(token)
      });

      await showAlertBaseOnResponseAndCode(deleteManager, {
        successMessage: i18n.t('services.successMessages.MANAGER_IS_DELETED', {customerName}),
        errorMessage: i18n.t('services.errorMessages.CANNOT_DELETE_MANAGER', {customerName}),
      });
    } catch {
      //
    }
  }

  async requestPasswordUpdate() {
    try {
      let authToken = await KeyCloakService.getAuthToken();
      let requestPass = await requestPasswordUpdateForResellerManagerUsingPUT({$config: authHeaders(authToken)});
      if (requestPass?.status === 200) {
        await KeyCloakService.logoutUser();
      }
    } catch {
      // do nothing
    }
  }

  async validateShortFormatUserName(suggestedUsername) {
    try {
      let token = await KeyCloakService.getAuthToken();
      let isValid = await validateCustomerAdminUsernameUsingPOST({
        userSuggestionDto: {suggestedUsername},
        $config: authHeaders(token)
      });

      return isValid.status === 200;
    } catch {
      return false;
    }
  }

  async retrieveResellerEmail() {
    try {
      let token = await KeyCloakService.getAuthToken();
      let resellerEmail = await getResellerInformationUsingGET({
        $config: authHeaders(token)
      });

      return resellerEmail.data;
    } catch {
      return false;
    }
  }

  async updateResellerEmail(resellerEmailDto) {
    try {
      let token = await KeyCloakService.getAuthToken();
      let resellerEmail = await updateEmailUsingPOST({
        resellerEmailDto,
        $config: authHeaders(token),
      });

      await showAlertBaseOnResponseAndCode(resellerEmail, {
        successMessage: i18n.t('services.successMessages.RESELLER_EMAIL_IS_SUCCESSFULLY_UPDATED'),
        errorMessage: i18n.t('services.errorMessages.CANNOT_UPDATE_RESELLER_EMAIL'),
        conflictMessage: i18n.t('services.errorMessages.CANNOT_UPDATE_RESELLER_EMAIL'),
      });
    } catch {
      return false;
    }
  }
}

export default new UserService();
