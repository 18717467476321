<template>
  <div>
    <ActionCard :title="$t('reseller.editEmailInfo.updateEmail')">
      <div slot="actionFields">
        <div>
          <v-text-field
              v-model="resellerEmail.notificationFromEmailAddress"
              outlined
              :label="$t('reseller.editEmailInfo.email')"
              validate-on-blur
              :rules="rules.emailRequiredRules"
              @input="hasResellerEmailChanged = true"
          />
          <v-text-field
              v-model="resellerEmail.notificationFromFriendlyName"
              outlined
              :label="$t('reseller.editEmailInfo.friendlyEmailName')"
              :rules="rules.fieldRequiredRules"
              validate-on-blur
              class="mt-2"
              @input="hasResellerEmailChanged = true"
          />
        </div>
      </div>
      <div slot="actionBtn" class="w-100-p mt-8">
        <div class="d-flex justify-end w-100-p">
          <v-btn
              class="text-none"
              color="primary"
              :disabled="!enableSaveEmail"
              @click="updateResellerEmailInfo"
          >
            {{ $t('reseller.editEmailInfo.save') }}
          </v-btn>
        </div>
      </div>

      <ActionCard/>
    </actioncard>
  </div>
</template>

<script>
import ActionCard from '@/components/cards/ActionCard';
import {isTruthy} from '@/util/guards';
import {mapActions, mapGetters} from 'vuex';
import {emailRequiredRules, fieldRequiredRules, isValidEmail} from '@/util/rules/inputRules';

export default {
  name: 'EditEmailInfo',
  components: {ActionCard},
  data() {
    return {
      rules: {
        fieldRequiredRules,
        emailRequiredRules
      },
      resellerEmail: {
        resellerUUID: null,
        notificationFromEmailAddress: null,
        notificationFromFriendlyName: null
      },
      hasResellerEmailChanged: false
    };
  },
  computed: {
    ...mapGetters('reseller', ['getResellerEmail']),
    enableSaveEmail() {
      return this.hasResellerEmailChanged
        && isValidEmail(this.resellerEmail.notificationFromEmailAddress)
        && isTruthy(this.resellerEmail.notificationFromFriendlyName);
    }
  },
  watch: {
    getResellerEmail: {
      handler() {
        this.resellerEmail = this.getResellerEmail;
        this.hasResellerEmailChanged = false;
      }
    }
  },
  mounted() {
    this.retrieveResellerEmail();
  },
  methods: {
    ...mapActions('reseller', ['retrieveResellerEmail', 'updateResellerEmail']),
    updateResellerEmailInfo() {
      this.updateResellerEmail({resellerEmailDto: this.resellerEmail});
      this.hasResellerEmailChanged = false;
    }
  }
};
</script>

<style scoped>

</style>
