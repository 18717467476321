const state = {
  selectedNavigation: null,
  selectedSubPages: null
};

const getters = {
  getSelectedNavigation: state => state.selectedNavigation,
  getSelectedSubPages: state => state.selectedSubPages,
};

const actions = {};

const mutations = {

  setSelectedNavigation(state, nav) {
    state.selectedNavigation = nav;
  },

  setSelectedSubPages(state, subPages) {
    state.selectedSubPages = subPages;
  },
};

export const navigation = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
