<template>
  <div>
    <div>
      <v-dialog
          v-model="openAddManager"
          persistent
          width="700"
          @keydown.esc="closeDialog"
      >
        <v-card height="640" width="700" class="pa-0">
          <v-card color="primary" height="70" class="rounded-b-0">
            <div class="d-flex justify-end pt-4 pr-4">
              <div @click="closeDialog">
                <v-icon color="white">mdi-close</v-icon>
              </div>
            </div>
            <div class="d-flex justify-center align-center white--text mt-n4">
              <div class="default-text">
                {{ $t('commons.manager.createManager') }}
              </div>
            </div>
          </v-card>
          <div class="ma-8">
            <div class="mt-4">
              <v-text-field
                  v-model="manager.firstName"
                  :label="$t('commons.manager.firstName')"
                  outlined
                  :rules="rules.fieldRequiredRules"
                  validate-on-blur
                  @input="fillUsernameWillUserInfo"
              />
            </div>
            <div class="mt-4">
              <v-text-field
                  v-model="manager.lastName"
                  :label="$t('commons.manager.lastName')"
                  outlined
                  :rules="rules.fieldRequiredRules"
                  validate-on-blur
                  @input="fillUsernameWillUserInfo"
              />
            </div>
            <div class="mt-4">
              <v-text-field
                  v-model="manager.email"
                  :label="$t('commons.manager.email')"
                  :rules="rules.emailRequiredRules"
                  outlined
                  validate-on-blur
                  @input="fillUsernameWillUserInfo"
              />
            </div>
            <div class="mt-4">
              <div class="mt-0 mb-2">
                <div v-if="getIsManagerUsernameValid">
                  {{ $t('commons.manager.usernameIsValid', {username: manager.username}) }}
                </div>
                <div v-else>
                  <span v-if="hasUserName" class="error--text">
                    {{ $t('commons.manager.usernameIsNotValid') }}
                  </span>
                </div>
              </div>
              <v-text-field
                  v-model="manager.username"
                  :label="$t('commons.manager.username')"
                  outlined
                  :rules="rules.fieldRequiredRules"
                  validate-on-blur
              />
            </div>
            <div class="mt-4">
              <PhoneInput :key="phoneNumberKey" @fullPhoneNumber="addPhoneNumber"/>
            </div>
          </div>
        </v-card>
        <div class="d-flex justify-center mt-n4">
          <v-btn class="text-none" color="primary" :disabled="!isValidFrom" @click="addManager">
            {{ $t('commons.manager.save') }}
          </v-btn>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {isFalsy, isTruthy} from '@/util/guards';
import {emailRequiredRules, fieldRequiredRules, isValidEmail,} from '@/util/rules/inputRules';
import {roles, userRoleAbbr} from '@/models/others/user/userRoles';
import PhoneInput from '@/components/input/PhoneInput';

export default {
  name: 'AddManagerDialog',
  components: {PhoneInput},
  data() {
    return {
      openAddManager: false,
      rules: {
        fieldRequiredRules,
        emailRequiredRules,
      },
      phoneNumber: null,
      phoneNumberKey: null,
      manager: {
        username: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
      }
    };
  },
  watch: {
    'manager.username': {
      handler() {
        this.validateUserName({username: this.manager.username});
      },
    }
  },
  computed: {
    ...mapGetters('reseller', ['getIsManagerUsernameValid']),
    isValidPhoneNumber() {
      return this.phoneNumber?.isValid;
    },
    isValidUserInfo() {
      let {firstName, lastName, email} = this.manager;
      return isTruthy(firstName) && isTruthy(lastName) && isValidEmail(email);
    },
    hasUserName() {
      return isTruthy(this.manager.username);
    },
    isValidFrom() {
      let {username} = this.manager;
      return this.isValidUserInfo
        && this.isValidPhoneNumber
        && isTruthy(username)
        && this.getIsManagerUsernameValid;
    },
    getQualifiedName() {
      return userRoleAbbr[roles.WHISTLEBLOWER_MANAGER] +
        '-' +
        this.get2FirstChars(this.manager?.firstName) +
        this.get2FirstChars(this.manager?.lastName) +
        '@' + this.getEmailDomain;
    },
    getEmailDomain() {
      return this.manager?.email?.split('@')[1];
    },
  },
  methods: {
    ...mapActions('reseller', ['addManagerToCompany', 'validateUserName']),
    closeDialog() {
      this.openAddManager = false;
      this.resetManager();
    },
    openDialog() {
      this.openAddManager = true;
    },
    resetManager() {
      this.manager = {username: null, firstName: null, lastName: null, email: null, phone: null,};
      this.phoneNumberKey = ++this.phoneNumberKey;
    },
    async addManager() {
      await this.addManagerToCompany({manager: this.manager});
      this.closeDialog();
    },
    fillUsernameWillUserInfo() {
      if (this.isValidUserInfo) {
        this.manager.username = this.getQualifiedName;
      }
    },
    addPhoneNumber(fullPhoneNumber) {
      this.phoneNumber = fullPhoneNumber;
      this.manager.phone = fullPhoneNumber.fullNumber;
    },
    get2FirstChars: prop => isFalsy(prop) ? '' : prop?.substring(0, 2).toLowerCase(),
  }
};
</script>

<style scoped>

</style>
