<template>
  <div>
    <v-dialog
        v-model="openCreateCustomer"
        persistent
        width="900"
        @click:outside="closeDialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="white"
            class="remove-focus rounded-pill d-flex justify-center align-center c-point text-none"
            height="52"
            width="230"
            elevation="0"
            v-bind="attrs"
            v-on="on"
        >
          <div class="d-flex justify-space-between pl-4 pr-4">
            <div class="default-text">
              {{ $t('commons.createCustomer.openCustomer') }}
            </div>
            <div class="pl-4">
              <v-icon color="primary">mdi-plus</v-icon>
            </div>
          </div>
        </v-btn>
      </template>

      <v-card height="480" width="900" class="pa-0">
        <v-card color="primary" height="70" class="rounded-b-0">
          <div class="d-flex justify-end pt-4 pr-4">
            <div @click="closeDialog()">
              <v-icon color="white">mdi-close</v-icon>
            </div>
          </div>
          <div class="d-flex justify-center align-center white--text mt-n4">
            <div class="default-text">
              {{ $t('commons.createCustomer.createCustomer') }}
            </div>
          </div>
        </v-card>
        <div class="d-flex justify-center">
          <div class="pa-8" style="width: 70%">
            <div class="mt-8">
              <v-text-field
                  :key="fieldReset"
                  v-model="customer.friendlyName"
                  :rules="rules.fieldRequiredRules"
                  outlined
                  validate-on-blur
                  :label="$t('commons.createCustomer.name')"
              />
            </div>
            <div class="mt-8">
              <div>
                <v-text-field
                    :key="fieldReset"
                    v-model="customer.name"
                    :rules="rules.fieldRequiredRules"
                    validate-on-blur
                    outlined :label="$t('commons.createCustomer.domain')"
                />
              </div>
              <div v-if="customer.name !== null">
                <v-checkbox v-model="domain.isValid" :label="$t('commons.createCustomer.isValid')" disabled/>
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <div class="d-flex justify-center mt-n5">
        <v-btn
            large
            class="text-none default-text"
            :disabled="!isValidForm"
            color="primary"
            @click="saveCustomer()"
        >
          {{ $t('commons.createCustomer.createButton') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import {isTruthy} from '@/util/guards';
import {fieldRequiredRules} from '@/util/rules/inputRules';
import _ from 'lodash';

export default {
  name: 'CreateCustomer',
  data() {
    return {
      rules: {
        fieldRequiredRules
      },
      domain: {
        suggested: null,
        customer: null,
        isValid: null,
      },
      customer: {
        name: null,
        friendlyName: null,
        license: null,
      },
      fieldReset: 1,
      openCreateCustomer: false
    };
  },
  computed: {
    isValidForm() {
      return this.domain.isValid
        && isTruthy(this.customer.name)
        && isTruthy(this.customer.friendlyName);
    }
  },
  watch: {
    'customer.name': {
      async handler() {
        this.validateDomainWithDelay();
      }
    }
  },
  mounted() {
    this.retrieveLicence();
  },
  methods: {
    ...mapActions('reseller', ['retrieveLicence', 'createCustomer', 'isDomainValid']),
    async saveCustomer() {
      await this.createCustomer({customer: this.customer});
      this.closeDialog();
    },
    closeDialog() {
      this.openCreateCustomer = false;
      this.resetDomain();
      this.resetCustomer();
      this.resetFields();
    },
    resetDomain() {
      this.domain = {suggested: null, customer: null, isValid: null,};
    },
    resetCustomer() {
      this.customer = {name: null, friendlyName: null, license: null,};
    },
    resetFields() {
      this.fieldReset = ++this.fieldReset;
    },
    validateDomainWithDelay: _.debounce(async function () {
      if (isTruthy(this.customer.name)) {
        this.domain.isValid = await this.isDomainValid({customerName: this.customer.name});
      }
    }, 700),
  }
};
</script>

<style scoped lang="scss">
</style>
