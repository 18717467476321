<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        app
        permanent
        mini-variant-width="70"
        width="320"
        color="white"
    >
      <v-list class="mt-3">
        <v-list-item class="w-100-p" @click="mini = !mini">
          <div class="w-100-p d-flex justify-end ml-1 mt-2">
            <v-btn class=" pa-1 elevation-0" icon color="primary">
              <v-icon v-if="mini" large>mdi-forwardburger</v-icon>
              <v-icon v-else large>mdi-backburger</v-icon>
            </v-btn>
          </div>
        </v-list-item>

        <div class="mt-5 ml-3">
          <v-btn icon large color="primary" @click="goHome">
            <v-icon large>mdi-home</v-icon>
          </v-btn>
        </div>

        <v-list-item
            v-for="subMenu in getSelectedSubPages"
            :key="subMenu.title"
            :to="subMenu.to"
            active-class="border-5-left"
        >
          <v-list-item-icon>
            <v-icon color="primary" large>{{ subMenu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title color="primary" class="default-text pt-1">
              {{ subMenu.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div>
        <div v-if="mini" class="d-flex justify-center">
          <v-btn icon large color="primary" class="logoutBtn ml-n10" @click="logout()">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </div>
        <div v-else>
          <v-btn width="190" outlined color="primary" class="logoutBtn text-none" @click="logout()">
            {{ $t('commons.logOut') }}
          </v-btn>
        </div>
      </div>
    </v-navigation-drawer>

    <v-app-bar app elevation="2" color="white" elevate-on-scroll height="75">
      <v-tabs align-with-title>
        <v-tab
            v-for="(item, index) in topBarMenuItems"
            :key="index"
            class="text-none"
            :to="item.to"
            active-class="font-weight-bold"
            @click="setSelectedLeftSidePages(item.subMenu)"
        >
          <span class="default-text">{{ item.title }}</span>
        </v-tab>
      </v-tabs>

      <v-spacer/>
      <div class="border-2-all rounded-pill mr-10 d-flex justify-space-between align-center">
        <div class="pa-3" @click="$router.push('/reseller/profile').catch(_=>{})">
          <span class="default-text primary--text">{{ nameChars }}</span>
        </div>
      </div>
    </v-app-bar>

    <v-main class="background">
      <v-container class="py-8 px-6 pl-13 pr-13" fluid>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import KeyCloakService from '@/service/authentication/KeyCloakService';
import {isFalsy} from '@/util/guards';
import {mapGetters, mapMutations} from 'vuex';
import {resellerNavigation} from '@/models/reseller/resellerNavigation';

export default {
  name: 'MainLayout',
  data() {
    return {
      drawer: false,
      mini: false,
      nameChars: ''
    };
  },
  computed: {
    ...mapGetters('navigation', ['getSelectedNavigation', 'getSelectedSubPages']),
    ...mapGetters('reseller', ['getLicense']),
    topBarMenuItems() {
      return this.getSelectedNavigation?.topBar?.filter(el => el.showTopBar);
    }
  },
  watch: {
    '$vuetify.breakpoint.width': {
      handler() {
        this.mini = this.$vuetify.breakpoint.width < 1300;
      },
    }
  },
  async mounted() {
    await this.updateToken();
    let userInfo = await KeyCloakService.getUserInformationFromKeycloak();
    this.nameChars = userInfo.name.split(' ').map(el => el.charAt(0)).join('');
  },
  methods: {
    ...mapMutations('navigation', ['setSelectedNavigation', 'setSelectedSubPages']),
    async logout() {
      await this.$router.push('/login');
      await KeyCloakService.logoutUser();
    },
    setSelectedLeftSidePages(subPages) {
      if (isFalsy(this.getLicense)) {
        this.setSelectedSubPages(subPages);
      } else {
        if (this.getLicense?.corporate) {
          this.setSelectedSubPages(subPages);
        } else {
          let nonCorporatePages = subPages.filter(el => !el?.corporateOnly);
          this.setSelectedSubPages(nonCorporatePages);
        }
      }
    },
    async updateToken() {
      setInterval(async () => {
        await KeyCloakService.refreshToken();
      }, 60000);
    },
    goHome() {
      this.setSelectedNavigation(resellerNavigation);
      this.setSelectedSubPages(resellerNavigation.topBar[0].subMenu);
      // eslint-disable-next-line no-unused-vars
      this.$router.push('/reseller/customer/active').catch(_ => {
      });
    }
  }
};
</script>

<style scoped lang="scss">


.logoutBtn {
  position: absolute;
  bottom: 70px;
  left: 60px;
}

</style>
