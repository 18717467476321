<template>
  <div>
    Hello
    <div v-for="(customer, index) in getAllCustomers" :key="index">
      <div>
        {{ customer.friendlyName }} - {{ customer.hasCases ? 'Har åbne sager' : 'Ingen åbne sager' }}
      </div>
    </div>
  </div>
</template>

<script>


import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'HasOpenCases',
  computed: {
    ...mapGetters('reseller', ['getAllCustomers'])
  },
  mounted() {
    this.retrieveAllCustomers();
  },
  methods: {
    ...mapActions('reseller', ['retrieveAllCustomers'])
  }
};

</script>

<style scoped>

</style>
