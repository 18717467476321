<template>
  <div class="mt-10">
    <v-card elevation="0" class="pa-8">
      <div class="d-flex justify-space-between">
        <div class="default-text primary--text">
          {{ $t('commons.licenseCard.companyLicense') }}
        </div>
        <div>
          <v-btn v-if="isEditMode" icon color="primary" @click="isEditMode = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn v-else icon color="primary" @click="isEditMode = true">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="custom-divider"/>
      <div v-if="isEditMode">
        <div class="mt-8">
          <v-select
              outlined
              :label="$t('commons.licenseCard.selectLicense')"
              :items="getLicense"
              item-value="type"
              :item-text="getLicenseTextTypes"
              @change="changeLicense"
          />
        </div>
        <div class="mt-8 d-flex justify-end">
          <v-btn class="text-none" color="primary" :disabled="!hasLicenseChanged" @click="updateCustomer">
            {{ $t('commons.licenseCard.updateLicense') }}
          </v-btn>
        </div>
      </div>
      <div v-else>
        <div class="mt-4">
          <div class="d-flex">
            <div class="mr-2" style="width: 300px">
              <span class="primary--text normal-text">
                {{ $t('commons.licenseCard.licenseType') }}
              </span>
            </div>
            <div class="ml-2 font-weight-bold">{{ getLicenseTextTypes(getSelectedCustomer.license) }}</div>
          </div>
          <div class="d-flex mt-4">
            <div class="mr-2" style="width: 300px">
              <span class="primary--text normal-text">
                {{ $t('commons.licenseCard.corporate') }}
              </span>
            </div>
            <div class="ml-2 font-weight-bold">
              {{
                getSelectedCustomer.license.corporate
                  ? $t('commons.licenseCard.yes')
                  : $t('commons.licenseCard.no')
              }}
            </div>
          </div>
          <div class="d-flex mt-4">
            <div class="mr-2" style="width: 300px">
              <span class="primary--text normal-text">
                {{ $t('commons.licenseCard.numberAIOs') }}
              </span>
            </div>
            <div class="ml-2 font-weight-bold">{{ getSelectedCustomer.license.maxNumberOfAIOs }}</div>
          </div>
          <div class="d-flex mt-4">
            <div class="mr-2" style="width: 300px">
              <span class="primary--text normal-text">
                {{ $t('commons.licenseCard.numberIOs') }}
              </span>
            </div>
            <div class="ml-2 font-weight-bold">{{ getSelectedCustomer.license.maxNumberOfIOs }}</div>
          </div>
          <div class="d-flex mt-4">
            <div class="mr-2" style="width: 300px">
              <span class="primary--text normal-text">
                {{ $t('commons.licenseCard.numberPOs') }}
              </span>
            </div>
            <div class="ml-2 font-weight-bold">{{ getSelectedCustomer.license.maxNumberOfPOs }}</div>
          </div>
          <div class="d-flex mt-4">
            <div class="mr-2" style="width: 300px">
              <span class="primary--text normal-text">
                {{ $t('commons.licenseCard.numberOfSubsidiaries') }}
              </span>
            </div>
            <div class="ml-2 font-weight-bold">{{ getSelectedCustomer.license.maxNumberOfSubsidiaries }}</div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'LicenseCard',
  data() {
    return {
      isEditMode: false,
      customer: null,
      hasLicenseChanged: false,
    };
  },
  computed: {
    ...mapGetters('reseller', ['getSelectedCustomer', 'getLicense']),
  },
  watch: {
    getSelectedCustomer: {
      handler() {
        this.initCustomer();
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    ...mapActions('reseller', ['updateCustomerLicense']),
    initCustomer() {
      this.customer = this.getSelectedCustomer;
    },
    changeLicense(license) {
      this.customer.license = this.getLicense.find(el => el.type === license);
      this.hasLicenseChanged = true;
    },
    updateCustomer() {
      this.updateCustomerLicense({license: this.customer.license});
      this.isEditMode = false;
      this.hasLicenseChanged = false;
    },
    getLicenseTextTypes(license) {
      return this.$t('reseller.licenseModelTypes.' + license.type);
    }
  }
};
</script>

<style scoped>

</style>
