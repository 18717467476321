<template>
  <div>
    <div class="page-header-title">{{ headerTitle }}</div>
    <div class="page-header-subtitle mt-3">{{ headerSubtitle }}</div>
  </div>
</template>

<script>
  export default {
    name: 'PageHeader',
    props: {
      headerTitle: {
        type: String,
        default: '',
      },
      headerSubtitle: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style scoped lang="scss">
.page-header-title {
  font-size: 20px;
  line-height: 20px;
  color: var(--v-primary-base);
}

.page-header-subtitle {
  font-size: 16px;
  line-height: 16px;
  color: var(--v-primary-base);
}
</style>
