import {isTruthy} from '@/util/guards';
import {showToastError, showToastSuccess} from '@/util/alert/showToastMsg';

const httpErrorCodes = {
  SUCCESS: 200,
  INTERNAL_SERVER_ERROR: 500,
  CONFLICT: 409,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
};

let isSuccess = (httpResponse) => httpResponse?.status === httpErrorCodes.SUCCESS;
let isInternalServerError = (httpResponse) => httpResponse?.response?.status === httpErrorCodes.INTERNAL_SERVER_ERROR;
let isConflict = (httpResponse) => httpResponse?.response?.status === httpErrorCodes.CONFLICT;
let isBadRequest = (httpResponse) => httpResponse?.response?.status === httpErrorCodes.BAD_REQUEST;
let isNotFound = (httpResponse) => httpResponse?.response?.status === httpErrorCodes.NOT_FOUND;

export const showAlertBaseOnResponseAndCode = async (
  httpResponse,
  {successMessage, errorMessage, conflictMessage, badRequestMessage, notFoundMessage}
) => {
  if (isTruthy(successMessage) && isSuccess(httpResponse)) {
    await showToastSuccess(successMessage);
  }

  if (isTruthy(errorMessage) && isInternalServerError(httpResponse)) {
    await showToastError(errorMessage);
  }

  if (isTruthy(conflictMessage) && isConflict(httpResponse)) {
    await showToastError(conflictMessage);
  }

  if (isTruthy(badRequestMessage) && isBadRequest(httpResponse)) {
    await showToastError(badRequestMessage);
  }

  if (isTruthy(notFoundMessage) && isNotFound(httpResponse)) {
    await showToastError(notFoundMessage);
  }
};
