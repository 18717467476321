<template>
  <div>
    <vue-phone-number-input
        v-model="phoneNr.text"
        class="h-100-p"
        :class="{'border-all-error-1': hasChanges && !phoneNr.isValid}"
        :color="phoneNr.color"
        :valid-color="$vuetify.theme.themes.light.primary"
        :error-color="$vuetify.theme.themes.light.error"
        :default-country-code="phoneNr.country"
        :translations="{
          countrySelectorLabel: $t('commons.input.phoneNr'),
          countrySelectorError: $t('commons.input.validPhoneNumber'),
          phoneNumberLabel: $t('commons.input.phoneNr'),
          example: $t('commons.input.examplePhoneNr'),
        }"
        @update="stepPhoneNumber"
    />
  </div>
</template>

<script>

import parsePhoneNumber from 'libphonenumber-js';

export default {
  name: 'PhoneInput',
  props: {
    phoneNumber: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      hasChanges: false,
      phoneNr: {
        text: null,
        color: this.$vuetify?.theme?.themes?.light?.primary ?? '',
        isValid: null,
        country: null,
      },
    };
  },
  mounted() {
    if (this.phoneNumber) {
      this.phoneNr.text = this.phoneNumber;
      this.phoneNr.country = parsePhoneNumber(this.phoneNumber).country;
    }
  },
  methods: {
    stepPhoneNumber(validation) {
      this.phoneNr.isValid = validation.isValid;
      if (validation?.e164) {
        this.hasChanges = true;
        this.$emit('fullPhoneNumber', {
          fullNumber: validation?.e164,
          isValid: validation.isValid
        });
      }
    },
  }
};
</script>

<style scoped>

</style>
