<template>
  <div class="mt-10">
    <AddManagerDialog ref="addManagerDialog"/>
    <v-card class="pa-8">
      <div class="d-flex justify-space-between">
        <div class="default-text primary--text">{{ $t('commons.manager.title') }}</div>
        <v-btn icon @click="addManager">
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
      </div>
      <div class="custom-divider"/>
      <div class="max-managers">
        <v-expansion-panels>
          <v-expansion-panel
              v-for="(manager, index) in getManager" :key="index"
              class="mb-2"
          >
            <v-expansion-panel-header class="default-text primary--text">
              {{ getManagerInfo(manager) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <EditManager :key="index" :manager="manager"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </div>
</template>

<script>
import AddManagerDialog from '@/components/dialog/AddManagerDialog';
import {mapGetters} from 'vuex';
import EditManager from '@/components/cards/manager/EditManager';

export default {
  name: 'ManagerCard',
  components: {EditManager, AddManagerDialog},
  computed: {
    ...mapGetters('reseller', ['getSelectedCustomer']),
    getManager() {
      return this.getSelectedCustomer?.managers;
    },
  },
  methods: {
    addManager() {
      this.$refs.addManagerDialog.openDialog();
    },
    getManagerInfo: manager => `${manager?.firstName ?? ''} ${manager?.lastName ?? ''}`,
  }
};
</script>

<style scoped>

.max-managers {
  max-height: 600px;
  overflow-y: scroll;
  padding: 5px;
}
</style>
