
export const services = {
  errorMessages: {
    CANNOT_UPDATE_CUSTOMER: 'Kunde {customerName} kan ikke opdateres',
    CUSTOMER_WAS_NOT_CREATED: 'Kunde blev ikke oprettet!',
    CANNOT_UPDATE_LICENSE_FOR_CUSTOMER: 'Licens til kunde {customerName} kan ikke opdateres',
    CANNOT_CREATE_MANAGER: 'Kan ikke oprette manager for kunde {customerName}',
    CANNOT_UPDATE_MANAGER: 'Kan ikke opdatere manager for kunde {customerName}',
    CANNOT_DELETE_MANAGER: '´Kan ikke slette manager for kunde {customerName}',
    CANNOT_UPDATE_RESELLER_EMAIL: 'Kan ikke opdatere system email',
    CANNOT_DELETE_CUSTOMER: 'Fejl opstod. Kunden kunne ikke slettes',
  },
  successMessages: {
    CUSTOMER_IS_SUCCESSFULLY_UPDATED: 'Kunde {customerName} blev opdateret',
    LICENSE_FOR_CUSTOMER_IS_UPDATED: 'Licens til kunde {customerName} blev opdateret',
    CUSTOMER_WAS_CREATED_SUCCESSFULLY: 'Kunde blev oprettet!',
    MANAGER_IS_CREATED: 'Manager blev oprettet for kunde {customerName}',
    MANAGER_IS_UPDATED: 'Manager blev opdateret for kunde {customerName}',
    MANAGER_IS_DELETED: 'Manager blev slettet for kunde {customerName}',
    RESELLER_EMAIL_IS_SUCCESSFULLY_UPDATED: 'System email opdateret',
    CUSTOMER_WAS_DELETED_SUCCESSFULLY: 'Kunden blev slettet!',
  }
};
