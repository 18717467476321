import KeyCloakService from '@/service/authentication/KeyCloakService';

import store from '@/store';

export const validatePrivateRoute = async (to, from, next) => {
  await store.dispatch('event/startLoading');
  let isAuth = await KeyCloakService.isUserAuthenticated();

  if (!isAuth) {
    try {
      await KeyCloakService.loginUser();
      await store.dispatch('event/stopLoading');
      next();
    } catch (err) {
      await store.dispatch('event/stopLoading');
      next('/');
    }
  } else {
    await store.dispatch('event/stopLoading');
    next();
  }
};

