<template>
  <v-app id="inspire">
    <div style="height: 50%; width: 100%" class="d-flex justify-center align-center">
      <v-btn class="text-none default-text" large color="primary" @click="login()">Login</v-btn>
    </div>
  </v-app>
</template>

<script>
import KeyCloakService from '@/service/authentication/KeyCloakService';

export default {
  name: 'Login',
  methods: {
    async login() {
      await KeyCloakService.loginUser();
    }
  }
};
</script>

<style scoped>

</style>
