const appVersion = 'app_version';

class ApplicationVersionStorage {

    setAppVersion(version) {
        localStorage.setItem(appVersion, version);
    }

    getAppVersion() {
        return localStorage.getItem(appVersion);
    }
}

export default new ApplicationVersionStorage();
