<template>
  <div>
    <div class="d-flex justify-end">
      <div>
        <v-btn v-if="isEditMode" icon class="ml-4" @click="closeEditMode">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
        <div v-else>
          <div class="d-flex">
            <ConfirmDeleteManagerDialog :manager="manager"/>

            <v-btn icon @click="isEditMode = true">
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEditMode">
      <div class="mt-4">
        <v-text-field
            v-model="managerToEdit.firstName"
            outlined
            :label="$t('commons.manager.firstName')"
            :rules="rules.fieldRequiredRules"
            :error="showError"
        />
      </div>
      <div class="mt-4">
        <v-text-field
            v-model="managerToEdit.lastName"
            outlined
            :label="$t('commons.manager.lastName')"
            :rules="rules.fieldRequiredRules"
            :error="showError"
        />
      </div>
      <div class="mt-4">
        <v-text-field
            v-model="managerToEdit.email"
            outlined
            :label="$t('commons.manager.email')"
            :rules="rules.fieldRequiredRules"
            :error="showError"
        />
      </div>
      <div class="mt-4">
        <PhoneInput :key="phoneNumberKey" :phone-number="manager.phone" @fullPhoneNumber="addPhoneNumber"/>
      </div>

      <div class="mt-4 d-flex justify-end">
        <v-btn class="text-none" color="primary" :disabled="!isValidFrom" @click="updateManager">
          {{ $t('commons.manager.update') }}
        </v-btn>
      </div>
    </div>
    <div v-else>
      <div>
        <div class="d-flex mt-4">
          <div class="mr-2 w-300-px">
            <span class="primary--text normal-text">
              {{ $t('commons.manager.firstName') }}
            </span>
          </div>
          <div class="ml-2 font-weight-bold">{{ manager.firstName }}</div>
        </div>
        <div class="d-flex mt-4">
          <div class="mr-2 w-300-px">
            <span class="primary--text normal-text">
              {{ $t('commons.manager.lastName') }}
            </span>
          </div>
          <div class="ml-2 font-weight-bold">{{ manager.lastName }}</div>
        </div>
        <div class="d-flex mt-4">
          <div class="mr-2 w-300-px">
            <span class="primary--text normal-text">
              {{ $t('commons.manager.email') }}
            </span>
          </div>
          <div class="ml-2 font-weight-bold">{{ manager.email }}</div>
        </div>
        <div class="d-flex mt-4">
          <div class="mr-2 w-300-px">
            <span class="primary--text normal-text">
              {{ $t('commons.manager.phone') }}
            </span>
          </div>
          <div class="ml-2 font-weight-bold">{{ manager.phone }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fieldRequiredRules} from '@/util/rules/inputRules';
import {managerModel} from '@/models/others/manager';
import {isTruthy} from '@/util/guards';
import {mapActions} from 'vuex';
import {deReactiveObject} from '@/util/deReactive';
import PhoneInput from '@/components/input/PhoneInput';
import ConfirmDeleteManagerDialog from '@/components/dialog/ConfirmDeleteManagerDialog';

export default {
  name: 'EditManager',
  components: {ConfirmDeleteManagerDialog, PhoneInput},
  props: {
    manager: {
      type: Object
    }
  },
  computed: {
    isValidFrom() {
      let {firstName, lastName, email} = this.managerToEdit;
      return isTruthy(firstName) && isTruthy(lastName) && isTruthy(email) && this.phoneNumber?.isValid;
    }
  },
  data() {
    return {
      rules: {fieldRequiredRules,},
      isEditMode: false,
      showError: false,
      managerToEdit: managerModel,
      phoneNumber: null,
      phoneNumberKey: 1,
    };
  },
  mounted() {
    this.managerToEdit = deReactiveObject(this.manager);
  },
  methods: {
    ...mapActions('reseller', ['updateManagerUser']),
    updateManager() {
      if (this.isValidFrom) {
        this.updateManagerUser({manager: this.managerToEdit});
        this.isEditMode = false;
      } else {
        this.showError = true;
      }
    },
    addPhoneNumber(fullNumber) {
      this.phoneNumber = fullNumber;
      this.managerToEdit.phone = fullNumber.fullNumber;
    },
    closeEditMode() {
      this.isEditMode = false;
      this.phoneNumberKey = ++this.phoneNumberKey;
    },
  }
};
</script>

<style scoped>

.w-300-px {
  width: 300px;
}

</style>
