export const keycloakUserInfo = {
  sub: null,
  email_verified: null,
  resellerUUID: null,
  name: null,
  preferred_username: null,
  given_name: null,
  family_name: null,
  customerName: null,
  email: null,
};
