export const accountRoles = {
  DEFAULT_ROLES_DAHL: 'default-roles-dahl',
  OFFLINE_ACCESS: 'offline_access',
  UMA_AUTHORIZATION: 'uma_authorization',

  WHISTLEBLOWER: 'whistleblower',

  ASSISTANT_INVESTIGATION_OFFICER: 'assistant-investigation-officer',
  INVESTIGATION_OFFICER: 'investigation-officer',
  PROTECTION_OFFICER: 'protection-officer',
  RESELLER_MANAGER: 'reseller-manager',
  WHISTLEBLOWER_MANAGER: 'whistleblower-manager',

  GENERAL_PAGE: 'general-page',
};
