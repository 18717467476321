<template>
  <div>
    <div class="mt-5">
      <v-card elevation="2">
        <v-card elevation="1" color="primary" class="rounded-b-0 pa-1">
          <v-card-title class="white--text ml-12">{{ $t('misc.profile.userInfo') }}</v-card-title>
        </v-card>
        <div class="card-inner-padding">
          <div>
            <div>
              <div class="font-weight-bold default-text primary--text">{{ $t('misc.profile.username') }}</div>
              <div class="font-weight-regular mt-2">{{ userInfo.preferred_username }}</div>
            </div>
          </div>
          <div class="mt-6">
            <div>
              <div class="font-weight-bold default-text primary--text">{{ $t('misc.profile.email') }}</div>
              <div class="font-weight-regular mt-2">{{ userInfo.email }}</div>
            </div>
          </div>
          <div class="mt-6">
            <div>
              <div class="font-weight-bold default-text primary--text">{{ $t('misc.profile.firstName') }}</div>
              <div class="font-weight-regular mt-2">{{ userInfo.given_name }}</div>
            </div>
          </div>
          <div class="mt-6">
            <div>
              <div class="font-weight-bold default-text primary--text">{{ $t('misc.profile.lastName') }}</div>
              <div class="font-weight-regular mt-2">{{ userInfo.family_name }}</div>
            </div>
          </div>
          <div class="mt-6">
            <div>
              <div class="font-weight-bold default-text primary--text">{{ $t('misc.profile.customerName') }}</div>
              <div class="font-weight-regular mt-2">{{ userInfo.customerName }}</div>
            </div>
          </div>
        </div>
      </v-card>
    </div>

    <ActionCard
        :title="$t('misc.profile.changePassTitle')"
        :desc="$t('misc.profile.changePassMessage')"
        class="pt-6"
    >
      <template v-slot:actionBtn>
        <v-btn class="text-none" color="primary" width="330" @click="requestPasswordUpdate">
          <span class="font-size-16">{{ $t('misc.profile.changePass') }}</span>
        </v-btn>
      </template>
    </ActionCard>
  </div>
</template>

<script>
import KeyCloakService from '@/service/authentication/KeyCloakService';
import {mapActions} from 'vuex';
import {keycloakUserInfo} from '@/models/others/keycloakUserInfo';
import ActionCard from '@/components/cards/ActionCard';

export default {
  name: 'Profile',
  components: {ActionCard},
  data() {
    return {
      userInfo: keycloakUserInfo
    };
  },

  async mounted() {
    this.userInfo = await KeyCloakService.getUserInformationFromKeycloak();
  },
  methods: {
    ...mapActions('reseller', ['requestPasswordUpdate']),
  }
};
</script>

<style scoped>

</style>
