<template>
  <div>
    <div class="d-flex justify-end">
      <CreateCustomer/>
    </div>
    <div class="mt-10">
      <CustomerList :customers="getAllCustomers" can-see-operations/>
    </div>
  </div>
</template>

<script>
import CustomerList from '@/components/lists/CustomerList';
import {mapActions, mapGetters} from 'vuex';
import CreateCustomer from '@/components/dialog/CreateCustomer';

export default {
  name: 'ActiveCustomers',
  components: {CreateCustomer, CustomerList},
  computed: {
    ...mapGetters('reseller', ['getAllCustomers'])
  },
  mounted() {
    this.retrieveAllCustomers();
  },
  methods: {
    ...mapActions('reseller', ['retrieveAllCustomers'])
  }
};
</script>

<style scoped>

</style>
