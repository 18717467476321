import {commons} from '@/language/da/others/commons';
import {reseller} from '@/language/da/reseller/reseller';
import {services} from '@/language/da/others/services';
import {misc} from '@/language/da/others/misc';


export const da = {
  commons,
  reseller,
  services,
  misc
};
