import Keycloak from 'keycloak-js';
import ConfigurationService from '@/service/ConfigurationService';
import Vue from 'vue';
import {registerKeyClockWithVueApplication} from '@/registerKeyCloak';

ConfigurationService.getIdFromConfiguration().then(initOptions => {
  const _keycloak = Keycloak(initOptions);

  const KeyCloakPlugin = {
    install: Vue => {
      Vue.$keycloak = _keycloak;
    },
  };

  KeyCloakPlugin.install = Vue => {
    Vue.$keycloak = _keycloak;
    Object.defineProperties(Vue.prototype, {
      $keycloak: {
        get() {
          return _keycloak;
        },
      },
    });
  };

  Vue.use(KeyCloakPlugin);

  registerKeyClockWithVueApplication(_keycloak);
});
