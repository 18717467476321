export const roles = {
  PROTECTION_OFFICER: 'protection-officer',
  INVESTIGATION_OFFICER: 'investigation-officer',
  ASSISTANT_INVESTIGATION_OFFICER: 'assistant-investigation-officer',
  WHISTLEBLOWER_MANAGER: 'whistleblower-manager',
  RESELLER_MANAGER: 'reseller-manager'
};

export const userRoleAbbr = {
  [roles.PROTECTION_OFFICER]: 'po',
  [roles.INVESTIGATION_OFFICER]: 'io',
  [roles.ASSISTANT_INVESTIGATION_OFFICER]: 'aio',
  [roles.WHISTLEBLOWER_MANAGER]: 'ma',
  [roles.RESELLER_MANAGER]: 'rs-ma'
};
