<template>
  <div>
    <div class="d-flex">
      <PageHeader
          class="mr-10"
          :header-title="$t('commons.editCustomer.company') + ' ' + getSelectedCustomer.friendlyName"
          :header-subtitle="$t('commons.editCustomer.domain') + ' ' + getSelectedCustomer.name"
      />
      <div class="d-flex align-start ml-10">
        <v-btn v-if="!isEditMode" icon color="primary" @click="isEditMode = true">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="isEditMode" class="mt-8">
      <v-card class="pa-8 pt-0">
        <div class="d-flex justify-end">
          <v-btn icon color="primary" class="mt-2 mb-6" @click="resetState">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-text-field
            v-model="customer.friendlyName"
            outlined
            :rules="rules.fieldRequiredRules"
            :label="$t('commons.editCustomer.friendlyName')"
            validate-on-blur
            @input="hasChangeDomain = true"
        />

        <div class="mt-6 d-flex justify-end">
          <v-btn
              class="text-none"
              :disabled="!isValidForm"
              color="primary"
              @click="updateName"
          >
            {{ $t('commons.editCustomer.updateName') }}
          </v-btn>
        </div>
      </v-card>
    </div>

    <ManagerCard/>

    <LicenseCard/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/header/PageHeader';
import LicenseCard from '@/components/cards/LicenseCard';
import ManagerCard from '@/components/cards/ManagerCard';
import {fieldRequiredRules} from '@/util/rules/inputRules';
import {isTruthy} from '@/util/guards';

export default {
  name: 'EditCustomer',
  components: {ManagerCard, LicenseCard, PageHeader},
  data() {
    return {
      isEditMode: false,
      customer: null,

      rules: {fieldRequiredRules},

      hasChangeDomain: false,
    };
  },
  computed: {
    ...mapGetters('reseller', ['getSelectedCustomer']),
    isValidForm() {
      return this.hasChangeDomain && isTruthy(this.customer.friendlyName);
    }
  },
  watch: {
    getSelectedCustomer: {
      handler() {
        this.customer = this.getSelectedCustomer;
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    ...mapActions('reseller', ['updateCustomerSelected']),
    updateName() {
      this.updateCustomerSelected({customer: this.customer});
      this.resetState();
    },
    resetState() {
      this.isEditMode = false;
      this.hasChangeDomain = false;
    }
  }
};
</script>

<style scoped>

</style>
