<template>
  <div>
    <MainLayout/>
  </div>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout';
export default {
  name: 'Dashboard',
  components: {MainLayout},
};
</script>

<style scoped lang="scss">
.v-application {
  background-color: var(--v-background-base) !important;
}
</style>
