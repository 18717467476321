import Vue from 'vue';


export const showToastSuccess = async (message)  => {
  await Vue.toasted.success(message, {
    position: 'top-right',
    duration: 4000
  });
};


export const showToastError = async (message)  => {
  await Vue.toasted.error(message, {
    position: 'top-right',
    duration: 4000
  });
};
