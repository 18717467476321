<template>
  <div class="pa-16">
    <div class="d-flex justify-center">
      <span class="primary--text font-size-24">{{ $t('commons.notFound.title') }}</span>
    </div>
    <div class="d-flex justify-center mt-16">
      <span class="font-size-20 primary--text">{{ $t('commons.notFound.takeMe') }}</span>
      <span class="font-size-20 pl-1 text-decoration-underline c-point primary--text"
            @click="$router.push('/')"
      >{{ $t('commons.notFound.home') }}</span>
    </div>

    <IllustrationPageNotFound/>
  </div>
</template>

<script>

import IllustrationPageNotFound from '@/components/illustration/IllustrationPageNotFound';
export default {
  name: 'NotFound',
  components: {IllustrationPageNotFound}
};
</script>

<style scoped>

</style>
