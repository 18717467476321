<template>
  <div>
    <v-row>
      <v-col cols="12" lg="3" md="3" sm="12">
        <div class="default-text text-uppercase cBlack--text pl-3">
          {{ $t('commons.list.organisation') }}
        </div>
      </v-col>
      <v-col cols="12" lg="3" md="3" sm="12">
        <div class="default-text text-uppercase cBlack--text pl-2">
          {{ $t('commons.list.licenseModel') }}
        </div>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="12">
        <div class="default-text text-uppercase cBlack--text">
          {{ $t('commons.list.domain') }}
        </div>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="12">
        <div class="default-text text-uppercase cBlack--text">
          {{ $t('commons.list.created') }}
        </div>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="12">
        <div class="default-text text-uppercase cBlack--text ml-n2">
          {{ $t('commons.list.actions') }}
        </div>
      </v-col>
    </v-row>
    <div class="mt-2">
      <v-list dense color="transparent">
        <v-list-item v-for="(customer, index) in customers" :key="index" class="mt-4 pl-0 pr-0">
          <v-card color="white" rounded elevation="0" width="100%" height="100%">
            <v-row class="pa-4">
              <v-col cols="12" lg="3" md="3" sm="12" class="d-flex justify-start align-center">
                <div class="normal-text">{{ customer.friendlyName }}</div>
              </v-col>
              <v-col cols="12" lg="2" md="2" sm="12" class="d-flex justify-start align-center">
                <div class="normal-text">{{ getLicenseTextTypes(customer) }}</div>
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="12" class="d-flex justify-center align-center">
                <div>
                  <div class="normal-text primary--text">{{ customer.name }}</div>
                </div>
              </v-col>
              <v-col cols="12" lg="2" md="2" sm="12" class="d-flex justify-start align-center">
                <div class="normal-text">{{ shortDateFormat(customer.createdTimestamp) }}</div>
              </v-col>
              <v-col cols="12" lg="2" md="2" sm="12" class="d-flex justify-end align-center">
                <div v-if="canSeeOperations" class="d-flex justify-space-around align-center" style="width: 100%">
                  <v-btn class="mr-1 text-none" color="primary" elevation="0" @click="editCustomer(customer)">
                    {{ $t('commons.list.edit') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>

import {mapMutations} from 'vuex';
import {editCustomerMenu} from '@/models/reseller/resellerNavigation';
import {shortDateFormat} from '@/util/dateFormat';

export default {
  name: 'CustomerList',
  props: {
    customers: {
      type: Array,
    },
    canSeeOperations: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations('reseller', ['setSelectedCustomer']),
    ...mapMutations('navigation', ['setSelectedNavigation', 'setSelectedSubPages']),
    editCustomer(customer) {
      this.setSelectedCustomer(customer);
      this.setSelectedSubPages(editCustomerMenu.subMenu);
      this.$router.push('/reseller/customer/edit');
    },
    getLicenseType: customer => customer?.license?.type,
    shortDateFormat,
    getLicenseTextTypes(customer) {
      let licenseType = this.getLicenseType(customer);
      return this.$t('reseller.licenseModelTypes.' + licenseType);
    }
  }
};
</script>

<style scoped>

</style>
