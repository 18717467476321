export const misc = {

  profile: {
    userInfo: 'Brugeroplysninger',
    username: 'Brugernavn',
    email: 'Email',
    firstName: 'Fornavn',
    lastName: 'Efternavn',
    customerName: 'Virksomhed',
    changePassTitle: 'Skift kodeord til sagen',
    changePassMessage: 'Hvis du ønsker at ændre dit kodeord til sagen, skal du trykke ‘Skift kodeord’ nedenfor:',
    changePass: 'Skift kodeord',
  },
};
