/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    Whistle Blower Api
 ==========================================================*/
/**
 * links
 * request: linksUsingGET
 * url: linksUsingGETURL
 * method: linksUsingGET_TYPE
 * raw_url: linksUsingGET_RAW_URL
 */
export const linksUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/actuator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const linksUsingGET_RAW_URL = function() {
  return '/actuator'
}
export const linksUsingGET_TYPE = function() {
  return 'get'
}
export const linksUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/actuator'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * handle
 * request: handleUsingGET_1
 * url: handleUsingGET_1URL
 * method: handleUsingGET_1_TYPE
 * raw_url: handleUsingGET_1_RAW_URL
 * @param body - body
 */
export const handleUsingGET_1 = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/actuator/health'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const handleUsingGET_1_RAW_URL = function() {
  return '/actuator/health'
}
export const handleUsingGET_1_TYPE = function() {
  return 'get'
}
export const handleUsingGET_1URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/actuator/health'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * handle
 * request: handleUsingGET
 * url: handleUsingGETURL
 * method: handleUsingGET_TYPE
 * raw_url: handleUsingGET_RAW_URL
 * @param body - body
 */
export const handleUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/actuator/health/**'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['body'] !== undefined) {
    body = parameters['body']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const handleUsingGET_RAW_URL = function() {
  return '/actuator/health/**'
}
export const handleUsingGET_TYPE = function() {
  return 'get'
}
export const handleUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/actuator/health/**'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * setApprovedAt
 * request: setApprovedAtUsingPUT
 * url: setApprovedAtUsingPUTURL
 * method: setApprovedAtUsingPUT_TYPE
 * raw_url: setApprovedAtUsingPUT_RAW_URL
 */
export const setApprovedAtUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/case/approve'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const setApprovedAtUsingPUT_RAW_URL = function() {
  return '/api/private/case/approve'
}
export const setApprovedAtUsingPUT_TYPE = function() {
  return 'put'
}
export const setApprovedAtUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/case/approve'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMessages
 * request: getMessagesUsingGET
 * url: getMessagesUsingGETURL
 * method: getMessagesUsingGET_TYPE
 * raw_url: getMessagesUsingGET_RAW_URL
 */
export const getMessagesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/case/messages'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMessagesUsingGET_RAW_URL = function() {
  return '/api/private/case/messages'
}
export const getMessagesUsingGET_TYPE = function() {
  return 'get'
}
export const getMessagesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/case/messages'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addMessage
 * request: addMessageUsingPOST
 * url: addMessageUsingPOSTURL
 * method: addMessageUsingPOST_TYPE
 * raw_url: addMessageUsingPOST_RAW_URL
 * @param files - files
 * @param messageDto - messageDto
 */
export const addMessageUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/case/messages/new'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['files'] !== undefined) {
    form['files'] = parameters['files']
  }
  if (parameters['messageDto'] !== undefined) {
    form['messageDto'] = parameters['messageDto']
  }
  if (parameters['messageDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: messageDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addMessageUsingPOST_RAW_URL = function() {
  return '/api/private/case/messages/new'
}
export const addMessageUsingPOST_TYPE = function() {
  return 'post'
}
export const addMessageUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/case/messages/new'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCase
 * request: getCaseUsingGET
 * url: getCaseUsingGETURL
 * method: getCaseUsingGET_TYPE
 * raw_url: getCaseUsingGET_RAW_URL
 */
export const getCaseUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/case/my-case'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCaseUsingGET_RAW_URL = function() {
  return '/api/private/case/my-case'
}
export const getCaseUsingGET_TYPE = function() {
  return 'get'
}
export const getCaseUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/case/my-case'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * requestPasswordUpdateForWhistleblower
 * request: requestPasswordUpdateForWhistleblowerUsingPUT
 * url: requestPasswordUpdateForWhistleblowerUsingPUTURL
 * method: requestPasswordUpdateForWhistleblowerUsingPUT_TYPE
 * raw_url: requestPasswordUpdateForWhistleblowerUsingPUT_RAW_URL
 */
export const requestPasswordUpdateForWhistleblowerUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/case/password/request-update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const requestPasswordUpdateForWhistleblowerUsingPUT_RAW_URL = function() {
  return '/api/private/case/password/request-update'
}
export const requestPasswordUpdateForWhistleblowerUsingPUT_TYPE = function() {
  return 'put'
}
export const requestPasswordUpdateForWhistleblowerUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/case/password/request-update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * verifyToken
 * request: verifyTokenUsingGET
 * url: verifyTokenUsingGETURL
 * method: verifyTokenUsingGET_TYPE
 * raw_url: verifyTokenUsingGET_RAW_URL
 */
export const verifyTokenUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/case/token/verify'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const verifyTokenUsingGET_RAW_URL = function() {
  return '/api/private/case/token/verify'
}
export const verifyTokenUsingGET_TYPE = function() {
  return 'get'
}
export const verifyTokenUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/case/token/verify'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * unsetPendingWhistleblowerAction
 * request: unsetPendingWhistleblowerActionUsingPOST
 * url: unsetPendingWhistleblowerActionUsingPOSTURL
 * method: unsetPendingWhistleblowerActionUsingPOST_TYPE
 * raw_url: unsetPendingWhistleblowerActionUsingPOST_RAW_URL
 */
export const unsetPendingWhistleblowerActionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/case/unset-pending-whistleblower-action'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const unsetPendingWhistleblowerActionUsingPOST_RAW_URL = function() {
  return '/api/private/case/unset-pending-whistleblower-action'
}
export const unsetPendingWhistleblowerActionUsingPOST_TYPE = function() {
  return 'post'
}
export const unsetPendingWhistleblowerActionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/case/unset-pending-whistleblower-action'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateWhistleblower
 * request: updateWhistleblowerUsingPOST
 * url: updateWhistleblowerUsingPOSTURL
 * method: updateWhistleblowerUsingPOST_TYPE
 * raw_url: updateWhistleblowerUsingPOST_RAW_URL
 * @param whistleblowerDto - whistleblowerDto
 */
export const updateWhistleblowerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/case/whistleblower/update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['whistleblowerDto'] !== undefined) {
    body = parameters['whistleblowerDto']
  }
  if (parameters['whistleblowerDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: whistleblowerDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateWhistleblowerUsingPOST_RAW_URL = function() {
  return '/api/private/case/whistleblower/update'
}
export const updateWhistleblowerUsingPOST_TYPE = function() {
  return 'post'
}
export const updateWhistleblowerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/case/whistleblower/update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createCustomer
 * request: createCustomerUsingPOST
 * url: createCustomerUsingPOSTURL
 * method: createCustomerUsingPOST_TYPE
 * raw_url: createCustomerUsingPOST_RAW_URL
 * @param customer - customer
 */
export const createCustomerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['customer'] !== undefined) {
    body = parameters['customer']
  }
  if (parameters['customer'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customer'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createCustomerUsingPOST_RAW_URL = function() {
  return '/api/private/reseller/customer/create'
}
export const createCustomerUsingPOST_TYPE = function() {
  return 'post'
}
export const createCustomerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * validateCustomerAdminUsername
 * request: validateCustomerAdminUsernameUsingPOST
 * url: validateCustomerAdminUsernameUsingPOSTURL
 * method: validateCustomerAdminUsernameUsingPOST_TYPE
 * raw_url: validateCustomerAdminUsernameUsingPOST_RAW_URL
 * @param userSuggestionDto - userSuggestionDto
 */
export const validateCustomerAdminUsernameUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/validate-username/short'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userSuggestionDto'] !== undefined) {
    body = parameters['userSuggestionDto']
  }
  if (parameters['userSuggestionDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userSuggestionDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const validateCustomerAdminUsernameUsingPOST_RAW_URL = function() {
  return '/api/private/reseller/customer/validate-username/short'
}
export const validateCustomerAdminUsernameUsingPOST_TYPE = function() {
  return 'post'
}
export const validateCustomerAdminUsernameUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/validate-username/short'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * confirmCustomerExists
 * request: confirmCustomerExistsUsingGET
 * url: confirmCustomerExistsUsingGETURL
 * method: confirmCustomerExistsUsingGET_TYPE
 * raw_url: confirmCustomerExistsUsingGET_RAW_URL
 * @param customerName - customerName
 */
export const confirmCustomerExistsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/{customerName}/confirm-name'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters['customerName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customerName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const confirmCustomerExistsUsingGET_RAW_URL = function() {
  return '/api/private/reseller/customer/{customerName}/confirm-name'
}
export const confirmCustomerExistsUsingGET_TYPE = function() {
  return 'get'
}
export const confirmCustomerExistsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/{customerName}/confirm-name'
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteCustomer
 * request: deleteCustomerUsingPOST
 * url: deleteCustomerUsingPOSTURL
 * method: deleteCustomerUsingPOST_TYPE
 * raw_url: deleteCustomerUsingPOST_RAW_URL
 * @param customerName - customerName
 */
export const deleteCustomerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/{customerName}/delete'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters['customerName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customerName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const deleteCustomerUsingPOST_RAW_URL = function() {
  return '/api/private/reseller/customer/{customerName}/delete'
}
export const deleteCustomerUsingPOST_TYPE = function() {
  return 'post'
}
export const deleteCustomerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/{customerName}/delete'
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * selectLicense
 * request: selectLicenseUsingPUT
 * url: selectLicenseUsingPUTURL
 * method: selectLicenseUsingPUT_TYPE
 * raw_url: selectLicenseUsingPUT_RAW_URL
 * @param customerName - customerName
 * @param licenseSelection - licenseSelection
 */
export const selectLicenseUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/{customerName}/license/select'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters['customerName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customerName'))
  }
  if (parameters['licenseSelection'] !== undefined) {
    body = parameters['licenseSelection']
  }
  if (parameters['licenseSelection'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: licenseSelection'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const selectLicenseUsingPUT_RAW_URL = function() {
  return '/api/private/reseller/customer/{customerName}/license/select'
}
export const selectLicenseUsingPUT_TYPE = function() {
  return 'put'
}
export const selectLicenseUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/{customerName}/license/select'
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addSubsidiaryToCooperateCustomer
 * request: addSubsidiaryToCooperateCustomerUsingPOST
 * url: addSubsidiaryToCooperateCustomerUsingPOSTURL
 * method: addSubsidiaryToCooperateCustomerUsingPOST_TYPE
 * raw_url: addSubsidiaryToCooperateCustomerUsingPOST_RAW_URL
 * @param customerName - customerName
 * @param subsidiary - subsidiary
 */
export const addSubsidiaryToCooperateCustomerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/{customerName}/subsidiary/add'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters['customerName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customerName'))
  }
  if (parameters['subsidiary'] !== undefined) {
    body = parameters['subsidiary']
  }
  if (parameters['subsidiary'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: subsidiary'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addSubsidiaryToCooperateCustomerUsingPOST_RAW_URL = function() {
  return '/api/private/reseller/customer/{customerName}/subsidiary/add'
}
export const addSubsidiaryToCooperateCustomerUsingPOST_TYPE = function() {
  return 'post'
}
export const addSubsidiaryToCooperateCustomerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/{customerName}/subsidiary/add'
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changeCustomerToCooperation
 * request: changeCustomerToCooperationUsingGET
 * url: changeCustomerToCooperationUsingGETURL
 * method: changeCustomerToCooperationUsingGET_TYPE
 * raw_url: changeCustomerToCooperationUsingGET_RAW_URL
 * @param customerName - customerName
 */
export const changeCustomerToCooperationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/{customerName}/to-cooperation'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters['customerName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customerName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const changeCustomerToCooperationUsingGET_RAW_URL = function() {
  return '/api/private/reseller/customer/{customerName}/to-cooperation'
}
export const changeCustomerToCooperationUsingGET_TYPE = function() {
  return 'get'
}
export const changeCustomerToCooperationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/{customerName}/to-cooperation'
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateCustomer
 * request: updateCustomerUsingPUT
 * url: updateCustomerUsingPUTURL
 * method: updateCustomerUsingPUT_TYPE
 * raw_url: updateCustomerUsingPUT_RAW_URL
 * @param customerName - customerName
 * @param customerUpdate - customerUpdate
 */
export const updateCustomerUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/{customerName}/update'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters['customerName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customerName'))
  }
  if (parameters['customerUpdate'] !== undefined) {
    body = parameters['customerUpdate']
  }
  if (parameters['customerUpdate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customerUpdate'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateCustomerUsingPUT_RAW_URL = function() {
  return '/api/private/reseller/customer/{customerName}/update'
}
export const updateCustomerUsingPUT_TYPE = function() {
  return 'put'
}
export const updateCustomerUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/{customerName}/update'
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * validateCustomerName
 * request: validateCustomerNameUsingGET
 * url: validateCustomerNameUsingGETURL
 * method: validateCustomerNameUsingGET_TYPE
 * raw_url: validateCustomerNameUsingGET_RAW_URL
 * @param customerName - customerName
 */
export const validateCustomerNameUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/{customerName}/validate'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters['customerName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customerName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const validateCustomerNameUsingGET_RAW_URL = function() {
  return '/api/private/reseller/customer/{customerName}/validate'
}
export const validateCustomerNameUsingGET_TYPE = function() {
  return 'get'
}
export const validateCustomerNameUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/{customerName}/validate'
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addWhistleblowerManagerToCustomer
 * request: addWhistleblowerManagerToCustomerUsingPOST
 * url: addWhistleblowerManagerToCustomerUsingPOSTURL
 * method: addWhistleblowerManagerToCustomerUsingPOST_TYPE
 * raw_url: addWhistleblowerManagerToCustomerUsingPOST_RAW_URL
 * @param customerName - customerName
 * @param whistleblowerManager - whistleblowerManager
 */
export const addWhistleblowerManagerToCustomerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/{customerName}/wbmanager/add'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters['customerName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customerName'))
  }
  if (parameters['whistleblowerManager'] !== undefined) {
    body = parameters['whistleblowerManager']
  }
  if (parameters['whistleblowerManager'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: whistleblowerManager'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addWhistleblowerManagerToCustomerUsingPOST_RAW_URL = function() {
  return '/api/private/reseller/customer/{customerName}/wbmanager/add'
}
export const addWhistleblowerManagerToCustomerUsingPOST_TYPE = function() {
  return 'post'
}
export const addWhistleblowerManagerToCustomerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/{customerName}/wbmanager/add'
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteWhistleblowerManager
 * request: deleteWhistleblowerManagerUsingPOST
 * url: deleteWhistleblowerManagerUsingPOSTURL
 * method: deleteWhistleblowerManagerUsingPOST_TYPE
 * raw_url: deleteWhistleblowerManagerUsingPOST_RAW_URL
 * @param customerName - customerName
 * @param whistleblowerManager - whistleblowerManager
 */
export const deleteWhistleblowerManagerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/{customerName}/wbmanager/delete'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters['customerName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customerName'))
  }
  if (parameters['whistleblowerManager'] !== undefined) {
    body = parameters['whistleblowerManager']
  }
  if (parameters['whistleblowerManager'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: whistleblowerManager'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const deleteWhistleblowerManagerUsingPOST_RAW_URL = function() {
  return '/api/private/reseller/customer/{customerName}/wbmanager/delete'
}
export const deleteWhistleblowerManagerUsingPOST_TYPE = function() {
  return 'post'
}
export const deleteWhistleblowerManagerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/{customerName}/wbmanager/delete'
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateWhistleblowerManager
 * request: updateWhistleblowerManagerUsingPOST
 * url: updateWhistleblowerManagerUsingPOSTURL
 * method: updateWhistleblowerManagerUsingPOST_TYPE
 * raw_url: updateWhistleblowerManagerUsingPOST_RAW_URL
 * @param customerName - customerName
 * @param whistleblowerManager - whistleblowerManager
 */
export const updateWhistleblowerManagerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customer/{customerName}/wbmanager/update'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters['customerName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: customerName'))
  }
  if (parameters['whistleblowerManager'] !== undefined) {
    body = parameters['whistleblowerManager']
  }
  if (parameters['whistleblowerManager'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: whistleblowerManager'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateWhistleblowerManagerUsingPOST_RAW_URL = function() {
  return '/api/private/reseller/customer/{customerName}/wbmanager/update'
}
export const updateWhistleblowerManagerUsingPOST_TYPE = function() {
  return 'post'
}
export const updateWhistleblowerManagerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customer/{customerName}/wbmanager/update'
  path = path.replace('{customerName}', `${parameters['customerName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMyCustomers
 * request: getMyCustomersUsingGET
 * url: getMyCustomersUsingGETURL
 * method: getMyCustomersUsingGET_TYPE
 * raw_url: getMyCustomersUsingGET_RAW_URL
 */
export const getMyCustomersUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/customers/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMyCustomersUsingGET_RAW_URL = function() {
  return '/api/private/reseller/customers/all'
}
export const getMyCustomersUsingGET_TYPE = function() {
  return 'get'
}
export const getMyCustomersUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/customers/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateEmail
 * request: updateEmailUsingPOST
 * url: updateEmailUsingPOSTURL
 * method: updateEmailUsingPOST_TYPE
 * raw_url: updateEmailUsingPOST_RAW_URL
 * @param resellerEmailDto - resellerEmailDto
 */
export const updateEmailUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/email/update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['resellerEmailDto'] !== undefined) {
    body = parameters['resellerEmailDto']
  }
  if (parameters['resellerEmailDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resellerEmailDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateEmailUsingPOST_RAW_URL = function() {
  return '/api/private/reseller/email/update'
}
export const updateEmailUsingPOST_TYPE = function() {
  return 'post'
}
export const updateEmailUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/email/update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getResellerInformation
 * request: getResellerInformationUsingGET
 * url: getResellerInformationUsingGETURL
 * method: getResellerInformationUsingGET_TYPE
 * raw_url: getResellerInformationUsingGET_RAW_URL
 */
export const getResellerInformationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/information'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getResellerInformationUsingGET_RAW_URL = function() {
  return '/api/private/reseller/information'
}
export const getResellerInformationUsingGET_TYPE = function() {
  return 'get'
}
export const getResellerInformationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/information'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSupportedLicense
 * request: getSupportedLicenseUsingGET
 * url: getSupportedLicenseUsingGETURL
 * method: getSupportedLicenseUsingGET_TYPE
 * raw_url: getSupportedLicenseUsingGET_RAW_URL
 */
export const getSupportedLicenseUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/license/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSupportedLicenseUsingGET_RAW_URL = function() {
  return '/api/private/reseller/license/all'
}
export const getSupportedLicenseUsingGET_TYPE = function() {
  return 'get'
}
export const getSupportedLicenseUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/license/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * requestPasswordUpdateForResellerManager
 * request: requestPasswordUpdateForResellerManagerUsingPUT
 * url: requestPasswordUpdateForResellerManagerUsingPUTURL
 * method: requestPasswordUpdateForResellerManagerUsingPUT_TYPE
 * raw_url: requestPasswordUpdateForResellerManagerUsingPUT_RAW_URL
 */
export const requestPasswordUpdateForResellerManagerUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/reseller/password/request-update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const requestPasswordUpdateForResellerManagerUsingPUT_RAW_URL = function() {
  return '/api/private/reseller/password/request-update'
}
export const requestPasswordUpdateForResellerManagerUsingPUT_TYPE = function() {
  return 'put'
}
export const requestPasswordUpdateForResellerManagerUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/reseller/password/request-update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSiteLookAndFeelForManager
 * request: getSiteLookAndFeelForManagerUsingGET
 * url: getSiteLookAndFeelForManagerUsingGETURL
 * method: getSiteLookAndFeelForManagerUsingGET_TYPE
 * raw_url: getSiteLookAndFeelForManagerUsingGET_RAW_URL
 * @param languageCode - languageCode
 */
export const getSiteLookAndFeelForManagerUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/language/{languageCode}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{languageCode}', `${parameters['languageCode']}`)
  if (parameters['languageCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: languageCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSiteLookAndFeelForManagerUsingGET_RAW_URL = function() {
  return '/api/private/site-look-and-feel/language/{languageCode}'
}
export const getSiteLookAndFeelForManagerUsingGET_TYPE = function() {
  return 'get'
}
export const getSiteLookAndFeelForManagerUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/language/{languageCode}'
  path = path.replace('{languageCode}', `${parameters['languageCode']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disableLanguage
 * request: disableLanguageUsingPOST
 * url: disableLanguageUsingPOSTURL
 * method: disableLanguageUsingPOST_TYPE
 * raw_url: disableLanguageUsingPOST_RAW_URL
 * @param languageCode - languageCode
 */
export const disableLanguageUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/language/{languageCode}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{languageCode}', `${parameters['languageCode']}`)
  if (parameters['languageCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: languageCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const disableLanguageUsingPOST_RAW_URL = function() {
  return '/api/private/site-look-and-feel/language/{languageCode}/disable'
}
export const disableLanguageUsingPOST_TYPE = function() {
  return 'post'
}
export const disableLanguageUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/language/{languageCode}/disable'
  path = path.replace('{languageCode}', `${parameters['languageCode']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * enableLanguage
 * request: enableLanguageUsingPOST
 * url: enableLanguageUsingPOSTURL
 * method: enableLanguageUsingPOST_TYPE
 * raw_url: enableLanguageUsingPOST_RAW_URL
 * @param languageCode - languageCode
 */
export const enableLanguageUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/language/{languageCode}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{languageCode}', `${parameters['languageCode']}`)
  if (parameters['languageCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: languageCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const enableLanguageUsingPOST_RAW_URL = function() {
  return '/api/private/site-look-and-feel/language/{languageCode}/enable'
}
export const enableLanguageUsingPOST_TYPE = function() {
  return 'post'
}
export const enableLanguageUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/language/{languageCode}/enable'
  path = path.replace('{languageCode}', `${parameters['languageCode']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateAskAQuestionInfo
 * request: updateAskAQuestionInfoUsingPOST
 * url: updateAskAQuestionInfoUsingPOSTURL
 * method: updateAskAQuestionInfoUsingPOST_TYPE
 * raw_url: updateAskAQuestionInfoUsingPOST_RAW_URL
 * @param language - language
 * @param updateAskQuestionInfoDto - updateAskQuestionInfoDto
 */
export const updateAskAQuestionInfoUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/language/{language}/ask-a-question-info/update'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{language}', `${parameters['language']}`)
  if (parameters['language'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: language'))
  }
  if (parameters['updateAskQuestionInfoDto'] !== undefined) {
    body = parameters['updateAskQuestionInfoDto']
  }
  if (parameters['updateAskQuestionInfoDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: updateAskQuestionInfoDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateAskAQuestionInfoUsingPOST_RAW_URL = function() {
  return '/api/private/site-look-and-feel/language/{language}/ask-a-question-info/update'
}
export const updateAskAQuestionInfoUsingPOST_TYPE = function() {
  return 'post'
}
export const updateAskAQuestionInfoUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/language/{language}/ask-a-question-info/update'
  path = path.replace('{language}', `${parameters['language']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateSiteLookAndFeelColors
 * request: updateSiteLookAndFeelColorsUsingPOST
 * url: updateSiteLookAndFeelColorsUsingPOSTURL
 * method: updateSiteLookAndFeelColorsUsingPOST_TYPE
 * raw_url: updateSiteLookAndFeelColorsUsingPOST_RAW_URL
 * @param colorsDto - colorsDto
 * @param language - language
 */
export const updateSiteLookAndFeelColorsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/language/{language}/colors/update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['colorsDto'] !== undefined) {
    body = parameters['colorsDto']
  }
  if (parameters['colorsDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: colorsDto'))
  }
  path = path.replace('{language}', `${parameters['language']}`)
  if (parameters['language'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: language'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateSiteLookAndFeelColorsUsingPOST_RAW_URL = function() {
  return '/api/private/site-look-and-feel/language/{language}/colors/update'
}
export const updateSiteLookAndFeelColorsUsingPOST_TYPE = function() {
  return 'post'
}
export const updateSiteLookAndFeelColorsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/language/{language}/colors/update'
  path = path.replace('{language}', `${parameters['language']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateFrontPage
 * request: updateFrontPageUsingPOST
 * url: updateFrontPageUsingPOSTURL
 * method: updateFrontPageUsingPOST_TYPE
 * raw_url: updateFrontPageUsingPOST_RAW_URL
 * @param language - language
 * @param siteFrontPageDto - siteFrontPageDto
 */
export const updateFrontPageUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/language/{language}/front-page/update'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{language}', `${parameters['language']}`)
  if (parameters['language'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: language'))
  }
  if (parameters['siteFrontPageDto'] !== undefined) {
    body = parameters['siteFrontPageDto']
  }
  if (parameters['siteFrontPageDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: siteFrontPageDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateFrontPageUsingPOST_RAW_URL = function() {
  return '/api/private/site-look-and-feel/language/{language}/front-page/update'
}
export const updateFrontPageUsingPOST_TYPE = function() {
  return 'post'
}
export const updateFrontPageUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/language/{language}/front-page/update'
  path = path.replace('{language}', `${parameters['language']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addTextSection
 * request: addTextSectionUsingPOST
 * url: addTextSectionUsingPOSTURL
 * method: addTextSectionUsingPOST_TYPE
 * raw_url: addTextSectionUsingPOST_RAW_URL
 * @param language - language
 * @param pageType - pageType
 * @param textSection - textSection
 */
export const addTextSectionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/language/{language}/page/{pageType}/text-section/add'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{language}', `${parameters['language']}`)
  if (parameters['language'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: language'))
  }
  path = path.replace('{pageType}', `${parameters['pageType']}`)
  if (parameters['pageType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pageType'))
  }
  if (parameters['textSection'] !== undefined) {
    body = parameters['textSection']
  }
  if (parameters['textSection'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: textSection'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addTextSectionUsingPOST_RAW_URL = function() {
  return '/api/private/site-look-and-feel/language/{language}/page/{pageType}/text-section/add'
}
export const addTextSectionUsingPOST_TYPE = function() {
  return 'post'
}
export const addTextSectionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/language/{language}/page/{pageType}/text-section/add'
  path = path.replace('{language}', `${parameters['language']}`)
  path = path.replace('{pageType}', `${parameters['pageType']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSiteLookAndFeelLogoForManager
 * request: getSiteLookAndFeelLogoForManagerUsingGET
 * url: getSiteLookAndFeelLogoForManagerUsingGETURL
 * method: getSiteLookAndFeelLogoForManagerUsingGET_TYPE
 * raw_url: getSiteLookAndFeelLogoForManagerUsingGET_RAW_URL
 * @param logoFileName - logoFileName
 */
export const getSiteLookAndFeelLogoForManagerUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/logo/{logoFileName}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{logoFileName}', `${parameters['logoFileName']}`)
  if (parameters['logoFileName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: logoFileName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSiteLookAndFeelLogoForManagerUsingGET_RAW_URL = function() {
  return '/api/private/site-look-and-feel/logo/{logoFileName}'
}
export const getSiteLookAndFeelLogoForManagerUsingGET_TYPE = function() {
  return 'get'
}
export const getSiteLookAndFeelLogoForManagerUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/logo/{logoFileName}'
  path = path.replace('{logoFileName}', `${parameters['logoFileName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * setLogo
 * request: setLogoUsingPOST
 * url: setLogoUsingPOSTURL
 * method: setLogoUsingPOST_TYPE
 * raw_url: setLogoUsingPOST_RAW_URL
 * @param file - file
 */
export const setLogoUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/set-logo'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    form['file'] = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const setLogoUsingPOST_RAW_URL = function() {
  return '/api/private/site-look-and-feel/set-logo'
}
export const setLogoUsingPOST_TYPE = function() {
  return 'post'
}
export const setLogoUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/set-logo'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteTextSection
 * request: deleteTextSectionUsingPOST
 * url: deleteTextSectionUsingPOSTURL
 * method: deleteTextSectionUsingPOST_TYPE
 * raw_url: deleteTextSectionUsingPOST_RAW_URL
 * @param textSection - textSection
 */
export const deleteTextSectionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/text-section/delete'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['textSection'] !== undefined) {
    body = parameters['textSection']
  }
  if (parameters['textSection'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: textSection'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const deleteTextSectionUsingPOST_RAW_URL = function() {
  return '/api/private/site-look-and-feel/text-section/delete'
}
export const deleteTextSectionUsingPOST_TYPE = function() {
  return 'post'
}
export const deleteTextSectionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/text-section/delete'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateTextSection
 * request: updateTextSectionUsingPOST
 * url: updateTextSectionUsingPOSTURL
 * method: updateTextSectionUsingPOST_TYPE
 * raw_url: updateTextSectionUsingPOST_RAW_URL
 * @param textSection - textSection
 */
export const updateTextSectionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/text-section/update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['textSection'] !== undefined) {
    body = parameters['textSection']
  }
  if (parameters['textSection'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: textSection'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateTextSectionUsingPOST_RAW_URL = function() {
  return '/api/private/site-look-and-feel/text-section/update'
}
export const updateTextSectionUsingPOST_TYPE = function() {
  return 'post'
}
export const updateTextSectionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/text-section/update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateWhistleblowerChannels
 * request: updateWhistleblowerChannelsUsingPOST
 * url: updateWhistleblowerChannelsUsingPOSTURL
 * method: updateWhistleblowerChannelsUsingPOST_TYPE
 * raw_url: updateWhistleblowerChannelsUsingPOST_RAW_URL
 * @param siteFrontPageDto - siteFrontPageDto
 */
export const updateWhistleblowerChannelsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/site-look-and-feel/whistleblower-channels/update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['siteFrontPageDto'] !== undefined) {
    body = parameters['siteFrontPageDto']
  }
  if (parameters['siteFrontPageDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: siteFrontPageDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateWhistleblowerChannelsUsingPOST_RAW_URL = function() {
  return '/api/private/site-look-and-feel/whistleblower-channels/update'
}
export const updateWhistleblowerChannelsUsingPOST_TYPE = function() {
  return 'post'
}
export const updateWhistleblowerChannelsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/site-look-and-feel/whistleblower-channels/update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addAssistantInvestigationOfficer
 * request: addAssistantInvestigationOfficerUsingPOST
 * url: addAssistantInvestigationOfficerUsingPOSTURL
 * method: addAssistantInvestigationOfficerUsingPOST_TYPE
 * raw_url: addAssistantInvestigationOfficerUsingPOST_RAW_URL
 * @param assistantInvestigationOfficerDto - assistantInvestigationOfficerDto
 */
export const addAssistantInvestigationOfficerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/assistant-investigation-officer/add'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['assistantInvestigationOfficerDto'] !== undefined) {
    body = parameters['assistantInvestigationOfficerDto']
  }
  if (parameters['assistantInvestigationOfficerDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: assistantInvestigationOfficerDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addAssistantInvestigationOfficerUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/assistant-investigation-officer/add'
}
export const addAssistantInvestigationOfficerUsingPOST_TYPE = function() {
  return 'post'
}
export const addAssistantInvestigationOfficerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/assistant-investigation-officer/add'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCase
 * request: getCaseUsingGET_1
 * url: getCaseUsingGET_1URL
 * method: getCaseUsingGET_1_TYPE
 * raw_url: getCaseUsingGET_1_RAW_URL
 * @param caseNumber - case-number
 */
export const getCaseUsingGET_1 = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCaseUsingGET_1_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}'
}
export const getCaseUsingGET_1_TYPE = function() {
  return 'get'
}
export const getCaseUsingGET_1URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * assignAssistantOfficer
 * request: assignAssistantOfficerUsingPOST
 * url: assignAssistantOfficerUsingPOSTURL
 * method: assignAssistantOfficerUsingPOST_TYPE
 * raw_url: assignAssistantOfficerUsingPOST_RAW_URL
 * @param assistantOfficerUuid - assistant-officer-uuid
 * @param caseNumber - case-number
 */
export const assignAssistantOfficerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/assign/assistant-officer/{assistant-officer-uuid}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{assistant-officer-uuid}', `${parameters['assistantOfficerUuid']}`)
  if (parameters['assistantOfficerUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: assistantOfficerUuid'))
  }
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const assignAssistantOfficerUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/assign/assistant-officer/{assistant-officer-uuid}'
}
export const assignAssistantOfficerUsingPOST_TYPE = function() {
  return 'post'
}
export const assignAssistantOfficerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/assign/assistant-officer/{assistant-officer-uuid}'
  path = path.replace('{assistant-officer-uuid}', `${parameters['assistantOfficerUuid']}`)
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCaseRight
 * request: getCaseRightUsingGET
 * url: getCaseRightUsingGETURL
 * method: getCaseRightUsingGET_TYPE
 * raw_url: getCaseRightUsingGET_RAW_URL
 * @param assistantOfficerUuid - assistant-officer-uuid
 * @param caseNumber - case-number
 */
export const getCaseRightUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/assistant-officer/{assistant-officer-uuid}/access-right'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{assistant-officer-uuid}', `${parameters['assistantOfficerUuid']}`)
  if (parameters['assistantOfficerUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: assistantOfficerUuid'))
  }
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCaseRightUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/assistant-officer/{assistant-officer-uuid}/access-right'
}
export const getCaseRightUsingGET_TYPE = function() {
  return 'get'
}
export const getCaseRightUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/assistant-officer/{assistant-officer-uuid}/access-right'
  path = path.replace('{assistant-officer-uuid}', `${parameters['assistantOfficerUuid']}`)
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changeAccessRightForAssistantOfficer
 * request: changeAccessRightForAssistantOfficerUsingPUT
 * url: changeAccessRightForAssistantOfficerUsingPUTURL
 * method: changeAccessRightForAssistantOfficerUsingPUT_TYPE
 * raw_url: changeAccessRightForAssistantOfficerUsingPUT_RAW_URL
 * @param assistantOfficerUuid - assistant-officer-uuid
 * @param caseNumber - case-number
 */
export const changeAccessRightForAssistantOfficerUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/assistant-officer/{assistant-officer-uuid}/change-access-right'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{assistant-officer-uuid}', `${parameters['assistantOfficerUuid']}`)
  if (parameters['assistantOfficerUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: assistantOfficerUuid'))
  }
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const changeAccessRightForAssistantOfficerUsingPUT_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/assistant-officer/{assistant-officer-uuid}/change-access-right'
}
export const changeAccessRightForAssistantOfficerUsingPUT_TYPE = function() {
  return 'put'
}
export const changeAccessRightForAssistantOfficerUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/assistant-officer/{assistant-officer-uuid}/change-access-right'
  path = path.replace('{assistant-officer-uuid}', `${parameters['assistantOfficerUuid']}`)
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * closeCase
 * request: closeCaseUsingPOST
 * url: closeCaseUsingPOSTURL
 * method: closeCaseUsingPOST_TYPE
 * raw_url: closeCaseUsingPOST_RAW_URL
 * @param caseNumber - case-number
 */
export const closeCaseUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/close'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const closeCaseUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/close'
}
export const closeCaseUsingPOST_TYPE = function() {
  return 'post'
}
export const closeCaseUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/close'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateDeleteProcedure
 * request: updateDeleteProcedureUsingPOST
 * url: updateDeleteProcedureUsingPOSTURL
 * method: updateDeleteProcedureUsingPOST_TYPE
 * raw_url: updateDeleteProcedureUsingPOST_RAW_URL
 * @param caseNumber - case-number
 * @param deleteProcedure - deleteProcedure
 */
export const updateDeleteProcedureUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/delete-procedure/update'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters['deleteProcedure'] !== undefined) {
    body = parameters['deleteProcedure']
  }
  if (parameters['deleteProcedure'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: deleteProcedure'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateDeleteProcedureUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/delete-procedure/update'
}
export const updateDeleteProcedureUsingPOST_TYPE = function() {
  return 'post'
}
export const updateDeleteProcedureUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/delete-procedure/update'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateCaseDescription
 * request: updateCaseDescriptionUsingPOST
 * url: updateCaseDescriptionUsingPOSTURL
 * method: updateCaseDescriptionUsingPOST_TYPE
 * raw_url: updateCaseDescriptionUsingPOST_RAW_URL
 * @param caseNumber - case-number
 * @param caseDescription - caseDescription
 */
export const updateCaseDescriptionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/description/update'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters['caseDescription'] !== undefined) {
    body = parameters['caseDescription']
  }
  if (parameters['caseDescription'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseDescription'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateCaseDescriptionUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/description/update'
}
export const updateCaseDescriptionUsingPOST_TYPE = function() {
  return 'post'
}
export const updateCaseDescriptionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/description/update'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCaseDownloadSpecification
 * request: getCaseDownloadSpecificationUsingGET
 * url: getCaseDownloadSpecificationUsingGETURL
 * method: getCaseDownloadSpecificationUsingGET_TYPE
 * raw_url: getCaseDownloadSpecificationUsingGET_RAW_URL
 * @param caseNumber - case-number
 */
export const getCaseDownloadSpecificationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/download-specification'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCaseDownloadSpecificationUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/download-specification'
}
export const getCaseDownloadSpecificationUsingGET_TYPE = function() {
  return 'get'
}
export const getCaseDownloadSpecificationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/download-specification'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getInternalNotes
 * request: getInternalNotesUsingGET
 * url: getInternalNotesUsingGETURL
 * method: getInternalNotesUsingGET_TYPE
 * raw_url: getInternalNotesUsingGET_RAW_URL
 * @param caseNumber - case-number
 */
export const getInternalNotesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/get-notes'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getInternalNotesUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/get-notes'
}
export const getInternalNotesUsingGET_TYPE = function() {
  return 'get'
}
export const getInternalNotesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/get-notes'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * assignInvestigationOfficer
 * request: assignInvestigationOfficerUsingPOST
 * url: assignInvestigationOfficerUsingPOSTURL
 * method: assignInvestigationOfficerUsingPOST_TYPE
 * raw_url: assignInvestigationOfficerUsingPOST_RAW_URL
 * @param caseNumber - case-number
 * @param investigationOfficerUuid - investigation-officer-uuid
 */
export const assignInvestigationOfficerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/investigation-officer/assign/{investigation-officer-uuid}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  path = path.replace('{investigation-officer-uuid}', `${parameters['investigationOfficerUuid']}`)
  if (parameters['investigationOfficerUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: investigationOfficerUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const assignInvestigationOfficerUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/investigation-officer/assign/{investigation-officer-uuid}'
}
export const assignInvestigationOfficerUsingPOST_TYPE = function() {
  return 'post'
}
export const assignInvestigationOfficerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/investigation-officer/assign/{investigation-officer-uuid}'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  path = path.replace('{investigation-officer-uuid}', `${parameters['investigationOfficerUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * unassignInvestigationOfficer
 * request: unassignInvestigationOfficerUsingPOST
 * url: unassignInvestigationOfficerUsingPOSTURL
 * method: unassignInvestigationOfficerUsingPOST_TYPE
 * raw_url: unassignInvestigationOfficerUsingPOST_RAW_URL
 * @param caseNumber - case-number
 * @param investigationOfficerUuid - investigation-officer-uuid
 */
export const unassignInvestigationOfficerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/investigation-officer/unassign/{investigation-officer-uuid}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  path = path.replace('{investigation-officer-uuid}', `${parameters['investigationOfficerUuid']}`)
  if (parameters['investigationOfficerUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: investigationOfficerUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const unassignInvestigationOfficerUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/investigation-officer/unassign/{investigation-officer-uuid}'
}
export const unassignInvestigationOfficerUsingPOST_TYPE = function() {
  return 'post'
}
export const unassignInvestigationOfficerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/investigation-officer/unassign/{investigation-officer-uuid}'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  path = path.replace('{investigation-officer-uuid}', `${parameters['investigationOfficerUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * rejectCase
 * request: rejectCaseUsingPOST
 * url: rejectCaseUsingPOSTURL
 * method: rejectCaseUsingPOST_TYPE
 * raw_url: rejectCaseUsingPOST_RAW_URL
 * @param caseNumber - case-number
 * @param recommendationDto - recommendationDto
 */
export const rejectCaseUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/reject'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters['recommendationDto'] !== undefined) {
    body = parameters['recommendationDto']
  }
  if (parameters['recommendationDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recommendationDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const rejectCaseUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/reject'
}
export const rejectCaseUsingPOST_TYPE = function() {
  return 'post'
}
export const rejectCaseUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/reject'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * setResume
 * request: setResumeUsingPOST
 * url: setResumeUsingPOSTURL
 * method: setResumeUsingPOST_TYPE
 * raw_url: setResumeUsingPOST_RAW_URL
 * @param caseNumber - case-number
 * @param resume - resume
 */
export const setResumeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/resume/set'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters['resume'] !== undefined) {
    body = parameters['resume']
  }
  if (parameters['resume'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resume'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const setResumeUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/resume/set'
}
export const setResumeUsingPOST_TYPE = function() {
  return 'post'
}
export const setResumeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/resume/set'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * setPendingWhistleblowerAction
 * request: setPendingWhistleblowerActionUsingPOST
 * url: setPendingWhistleblowerActionUsingPOSTURL
 * method: setPendingWhistleblowerActionUsingPOST_TYPE
 * raw_url: setPendingWhistleblowerActionUsingPOST_RAW_URL
 * @param caseNumber - case-number
 */
export const setPendingWhistleblowerActionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/set-pending-whistleblower-action'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const setPendingWhistleblowerActionUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/set-pending-whistleblower-action'
}
export const setPendingWhistleblowerActionUsingPOST_TYPE = function() {
  return 'post'
}
export const setPendingWhistleblowerActionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/set-pending-whistleblower-action'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * unassignAssistantOfficer
 * request: unassignAssistantOfficerUsingPOST
 * url: unassignAssistantOfficerUsingPOSTURL
 * method: unassignAssistantOfficerUsingPOST_TYPE
 * raw_url: unassignAssistantOfficerUsingPOST_RAW_URL
 * @param assistantOfficerUuid - assistant-officer-uuid
 * @param caseNumber - case-number
 */
export const unassignAssistantOfficerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/unassign/assistant-officer/{assistant-officer-uuid}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{assistant-officer-uuid}', `${parameters['assistantOfficerUuid']}`)
  if (parameters['assistantOfficerUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: assistantOfficerUuid'))
  }
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const unassignAssistantOfficerUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/unassign/assistant-officer/{assistant-officer-uuid}'
}
export const unassignAssistantOfficerUsingPOST_TYPE = function() {
  return 'post'
}
export const unassignAssistantOfficerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/unassign/assistant-officer/{assistant-officer-uuid}'
  path = path.replace('{assistant-officer-uuid}', `${parameters['assistantOfficerUuid']}`)
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateCase
 * request: updateCaseUsingPOST
 * url: updateCaseUsingPOSTURL
 * method: updateCaseUsingPOST_TYPE
 * raw_url: updateCaseUsingPOST_RAW_URL
 * @param caseNumber - case-number
 * @param caseDto - caseDto
 * @param files - files
 */
export const updateCaseUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{case-number}/update'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters['caseDto'] !== undefined) {
    form['caseDto'] = parameters['caseDto']
  }
  if (parameters['caseDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseDto'))
  }
  if (parameters['files'] !== undefined) {
    form['files'] = parameters['files']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateCaseUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{case-number}/update'
}
export const updateCaseUsingPOST_TYPE = function() {
  return 'post'
}
export const updateCaseUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{case-number}/update'
  path = path.replace('{case-number}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addFeedback
 * request: addFeedbackUsingPOST
 * url: addFeedbackUsingPOSTURL
 * method: addFeedbackUsingPOST_TYPE
 * raw_url: addFeedbackUsingPOST_RAW_URL
 * @param caseNumber - caseNumber
 * @param messageDto - messageDto
 */
export const addFeedbackUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{caseNumber}/feedback/new'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters['messageDto'] !== undefined) {
    body = parameters['messageDto']
  }
  if (parameters['messageDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: messageDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addFeedbackUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{caseNumber}/feedback/new'
}
export const addFeedbackUsingPOST_TYPE = function() {
  return 'post'
}
export const addFeedbackUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{caseNumber}/feedback/new'
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addMessage
 * request: addMessageUsingPOST_1
 * url: addMessageUsingPOST_1URL
 * method: addMessageUsingPOST_1_TYPE
 * raw_url: addMessageUsingPOST_1_RAW_URL
 * @param caseNumber - caseNumber
 * @param files - files
 * @param messageDto - messageDto
 */
export const addMessageUsingPOST_1 = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{caseNumber}/messages/new'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters['files'] !== undefined) {
    form['files'] = parameters['files']
  }
  if (parameters['messageDto'] !== undefined) {
    form['messageDto'] = parameters['messageDto']
  }
  if (parameters['messageDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: messageDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addMessageUsingPOST_1_RAW_URL = function() {
  return '/api/private/wbunit/case/{caseNumber}/messages/new'
}
export const addMessageUsingPOST_1_TYPE = function() {
  return 'post'
}
export const addMessageUsingPOST_1URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{caseNumber}/messages/new'
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addInternalNote
 * request: addInternalNoteUsingPOST
 * url: addInternalNoteUsingPOSTURL
 * method: addInternalNoteUsingPOST_TYPE
 * raw_url: addInternalNoteUsingPOST_RAW_URL
 * @param baseInternalNote - baseInternalNote
 * @param caseNumber - caseNumber
 */
export const addInternalNoteUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{caseNumber}/notes/add'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['baseInternalNote'] !== undefined) {
    body = parameters['baseInternalNote']
  }
  if (parameters['baseInternalNote'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: baseInternalNote'))
  }
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addInternalNoteUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{caseNumber}/notes/add'
}
export const addInternalNoteUsingPOST_TYPE = function() {
  return 'post'
}
export const addInternalNoteUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{caseNumber}/notes/add'
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getRecommendation
 * request: getRecommendationUsingGET
 * url: getRecommendationUsingGETURL
 * method: getRecommendationUsingGET_TYPE
 * raw_url: getRecommendationUsingGET_RAW_URL
 * @param caseNumber - caseNumber
 */
export const getRecommendationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{caseNumber}/recommendation'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRecommendationUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/case/{caseNumber}/recommendation'
}
export const getRecommendationUsingGET_TYPE = function() {
  return 'get'
}
export const getRecommendationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{caseNumber}/recommendation'
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * releaseRecommendation
 * request: releaseRecommendationUsingPOST
 * url: releaseRecommendationUsingPOSTURL
 * method: releaseRecommendationUsingPOST_TYPE
 * raw_url: releaseRecommendationUsingPOST_RAW_URL
 * @param caseNumber - caseNumber
 */
export const releaseRecommendationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{caseNumber}/recommendation/release'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const releaseRecommendationUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{caseNumber}/recommendation/release'
}
export const releaseRecommendationUsingPOST_TYPE = function() {
  return 'post'
}
export const releaseRecommendationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{caseNumber}/recommendation/release'
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * setRecommendation
 * request: setRecommendationUsingPOST
 * url: setRecommendationUsingPOSTURL
 * method: setRecommendationUsingPOST_TYPE
 * raw_url: setRecommendationUsingPOST_RAW_URL
 * @param caseNumber - caseNumber
 * @param recommendationDto - recommendationDto
 */
export const setRecommendationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/case/{caseNumber}/recommendation/set'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters['caseNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseNumber'))
  }
  if (parameters['recommendationDto'] !== undefined) {
    body = parameters['recommendationDto']
  }
  if (parameters['recommendationDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recommendationDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const setRecommendationUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/case/{caseNumber}/recommendation/set'
}
export const setRecommendationUsingPOST_TYPE = function() {
  return 'post'
}
export const setRecommendationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/case/{caseNumber}/recommendation/set'
  path = path.replace('{caseNumber}', `${parameters['caseNumber']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCases
 * request: getCasesUsingGET
 * url: getCasesUsingGETURL
 * method: getCasesUsingGET_TYPE
 * raw_url: getCasesUsingGET_RAW_URL
 */
export const getCasesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/cases/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCasesUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/cases/all'
}
export const getCasesUsingGET_TYPE = function() {
  return 'get'
}
export const getCasesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/cases/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getClosedCases
 * request: getClosedCasesUsingGET
 * url: getClosedCasesUsingGETURL
 * method: getClosedCasesUsingGET_TYPE
 * raw_url: getClosedCasesUsingGET_RAW_URL
 */
export const getClosedCasesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/cases/closed'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getClosedCasesUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/cases/closed'
}
export const getClosedCasesUsingGET_TYPE = function() {
  return 'get'
}
export const getClosedCasesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/cases/closed'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getLicenseForCustomer
 * request: getLicenseForCustomerUsingGET
 * url: getLicenseForCustomerUsingGETURL
 * method: getLicenseForCustomerUsingGET_TYPE
 * raw_url: getLicenseForCustomerUsingGET_RAW_URL
 */
export const getLicenseForCustomerUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/customer/license'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getLicenseForCustomerUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/customer/license'
}
export const getLicenseForCustomerUsingGET_TYPE = function() {
  return 'get'
}
export const getLicenseForCustomerUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/customer/license'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * downloadCase
 * request: downloadCaseUsingGET
 * url: downloadCaseUsingGETURL
 * method: downloadCaseUsingGET_TYPE
 * raw_url: downloadCaseUsingGET_RAW_URL
 * @param uuid - uuid
 */
export const downloadCaseUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/download-case/{uuid}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadCaseUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/download-case/{uuid}'
}
export const downloadCaseUsingGET_TYPE = function() {
  return 'get'
}
export const downloadCaseUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/download-case/{uuid}'
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPdf
 * request: getPdfUsingGET
 * url: getPdfUsingGETURL
 * method: getPdfUsingGET_TYPE
 * raw_url: getPdfUsingGET_RAW_URL
 * @param uuid - uuid
 */
export const getPdfUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/get-pdf/{uuid}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPdfUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/get-pdf/{uuid}'
}
export const getPdfUsingGET_TYPE = function() {
  return 'get'
}
export const getPdfUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/get-pdf/{uuid}'
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addInvestigationOfficer
 * request: addInvestigationOfficerUsingPOST
 * url: addInvestigationOfficerUsingPOSTURL
 * method: addInvestigationOfficerUsingPOST_TYPE
 * raw_url: addInvestigationOfficerUsingPOST_RAW_URL
 * @param investigationOfficerDto - investigationOfficerDto
 */
export const addInvestigationOfficerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/investigation-officer/add'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['investigationOfficerDto'] !== undefined) {
    body = parameters['investigationOfficerDto']
  }
  if (parameters['investigationOfficerDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: investigationOfficerDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addInvestigationOfficerUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/investigation-officer/add'
}
export const addInvestigationOfficerUsingPOST_TYPE = function() {
  return 'post'
}
export const addInvestigationOfficerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/investigation-officer/add'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAvailableInvestigationOfficers
 * request: getAvailableInvestigationOfficersUsingPOST
 * url: getAvailableInvestigationOfficersUsingPOSTURL
 * method: getAvailableInvestigationOfficersUsingPOST_TYPE
 * raw_url: getAvailableInvestigationOfficersUsingPOST_RAW_URL
 */
export const getAvailableInvestigationOfficersUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/investigation-officers/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getAvailableInvestigationOfficersUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/investigation-officers/all'
}
export const getAvailableInvestigationOfficersUsingPOST_TYPE = function() {
  return 'post'
}
export const getAvailableInvestigationOfficersUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/investigation-officers/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMyAuthorities
 * request: getMyAuthoritiesUsingGET
 * url: getMyAuthoritiesUsingGETURL
 * method: getMyAuthoritiesUsingGET_TYPE
 * raw_url: getMyAuthoritiesUsingGET_RAW_URL
 */
export const getMyAuthoritiesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/my-authorities'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMyAuthoritiesUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/my-authorities'
}
export const getMyAuthoritiesUsingGET_TYPE = function() {
  return 'get'
}
export const getMyAuthoritiesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/my-authorities'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * requestPasswordUpdateForWbUnitUser
 * request: requestPasswordUpdateForWbUnitUserUsingPUT
 * url: requestPasswordUpdateForWbUnitUserUsingPUTURL
 * method: requestPasswordUpdateForWbUnitUserUsingPUT_TYPE
 * raw_url: requestPasswordUpdateForWbUnitUserUsingPUT_RAW_URL
 */
export const requestPasswordUpdateForWbUnitUserUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/password/request-update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const requestPasswordUpdateForWbUnitUserUsingPUT_RAW_URL = function() {
  return '/api/private/wbunit/password/request-update'
}
export const requestPasswordUpdateForWbUnitUserUsingPUT_TYPE = function() {
  return 'put'
}
export const requestPasswordUpdateForWbUnitUserUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/password/request-update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addProtectionOfficer
 * request: addProtectionOfficerUsingPOST
 * url: addProtectionOfficerUsingPOSTURL
 * method: addProtectionOfficerUsingPOST_TYPE
 * raw_url: addProtectionOfficerUsingPOST_RAW_URL
 * @param protectionOfficerDto - protectionOfficerDto
 */
export const addProtectionOfficerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/protection-officer/add'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['protectionOfficerDto'] !== undefined) {
    body = parameters['protectionOfficerDto']
  }
  if (parameters['protectionOfficerDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: protectionOfficerDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addProtectionOfficerUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/protection-officer/add'
}
export const addProtectionOfficerUsingPOST_TYPE = function() {
  return 'post'
}
export const addProtectionOfficerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/protection-officer/add'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * setProtectionOfficerLogo
 * request: setProtectionOfficerLogoUsingPUT
 * url: setProtectionOfficerLogoUsingPUTURL
 * method: setProtectionOfficerLogoUsingPUT_TYPE
 * raw_url: setProtectionOfficerLogoUsingPUT_RAW_URL
 * @param file - file
 * @param poUuid - po-uuid
 */
export const setProtectionOfficerLogoUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/protection-officer/{po-uuid}/logo'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    form['file'] = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  path = path.replace('{po-uuid}', `${parameters['poUuid']}`)
  if (parameters['poUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: poUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const setProtectionOfficerLogoUsingPUT_RAW_URL = function() {
  return '/api/private/wbunit/protection-officer/{po-uuid}/logo'
}
export const setProtectionOfficerLogoUsingPUT_TYPE = function() {
  return 'put'
}
export const setProtectionOfficerLogoUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/protection-officer/{po-uuid}/logo'
  path = path.replace('{po-uuid}', `${parameters['poUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSubsidiaries
 * request: getSubsidiariesUsingGET
 * url: getSubsidiariesUsingGETURL
 * method: getSubsidiariesUsingGET_TYPE
 * raw_url: getSubsidiariesUsingGET_RAW_URL
 */
export const getSubsidiariesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/subsidiaries'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSubsidiariesUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/subsidiaries'
}
export const getSubsidiariesUsingGET_TYPE = function() {
  return 'get'
}
export const getSubsidiariesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/subsidiaries'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addSubsidiary
 * request: addSubsidiaryUsingPOST
 * url: addSubsidiaryUsingPOSTURL
 * method: addSubsidiaryUsingPOST_TYPE
 * raw_url: addSubsidiaryUsingPOST_RAW_URL
 * @param subsidiary - subsidiary
 */
export const addSubsidiaryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/subsidiaries/add'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['subsidiary'] !== undefined) {
    body = parameters['subsidiary']
  }
  if (parameters['subsidiary'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: subsidiary'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addSubsidiaryUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/subsidiaries/add'
}
export const addSubsidiaryUsingPOST_TYPE = function() {
  return 'post'
}
export const addSubsidiaryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/subsidiaries/add'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteSubsidiary
 * request: deleteSubsidiaryUsingPOST
 * url: deleteSubsidiaryUsingPOSTURL
 * method: deleteSubsidiaryUsingPOST_TYPE
 * raw_url: deleteSubsidiaryUsingPOST_RAW_URL
 * @param subsidiary - subsidiary
 */
export const deleteSubsidiaryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/subsidiaries/delete'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['subsidiary'] !== undefined) {
    body = parameters['subsidiary']
  }
  if (parameters['subsidiary'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: subsidiary'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const deleteSubsidiaryUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/subsidiaries/delete'
}
export const deleteSubsidiaryUsingPOST_TYPE = function() {
  return 'post'
}
export const deleteSubsidiaryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/subsidiaries/delete'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteUser
 * request: deleteUserUsingPOST
 * url: deleteUserUsingPOSTURL
 * method: deleteUserUsingPOST_TYPE
 * raw_url: deleteUserUsingPOST_RAW_URL
 * @param userUuid - user-uuid
 */
export const deleteUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/user/{user-uuid}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{user-uuid}', `${parameters['userUuid']}`)
  if (parameters['userUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const deleteUserUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/user/{user-uuid}'
}
export const deleteUserUsingPOST_TYPE = function() {
  return 'post'
}
export const deleteUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/user/{user-uuid}'
  path = path.replace('{user-uuid}', `${parameters['userUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUsers
 * request: getUsersUsingGET
 * url: getUsersUsingGETURL
 * method: getUsersUsingGET_TYPE
 * raw_url: getUsersUsingGET_RAW_URL
 */
export const getUsersUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/users/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUsersUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/users/all'
}
export const getUsersUsingGET_TYPE = function() {
  return 'get'
}
export const getUsersUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/users/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAssistantInvestigationOfficerUsers
 * request: getAssistantInvestigationOfficerUsersUsingGET
 * url: getAssistantInvestigationOfficerUsersUsingGETURL
 * method: getAssistantInvestigationOfficerUsersUsingGET_TYPE
 * raw_url: getAssistantInvestigationOfficerUsersUsingGET_RAW_URL
 */
export const getAssistantInvestigationOfficerUsersUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/users/assistant-investigation-officers'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAssistantInvestigationOfficerUsersUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/users/assistant-investigation-officers'
}
export const getAssistantInvestigationOfficerUsersUsingGET_TYPE = function() {
  return 'get'
}
export const getAssistantInvestigationOfficerUsersUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/users/assistant-investigation-officers'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateWhistleblowerUnitUser
 * request: updateWhistleblowerUnitUserUsingPOST
 * url: updateWhistleblowerUnitUserUsingPOSTURL
 * method: updateWhistleblowerUnitUserUsingPOST_TYPE
 * raw_url: updateWhistleblowerUnitUserUsingPOST_RAW_URL
 * @param unitUser - unitUser
 */
export const updateWhistleblowerUnitUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/users/update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['unitUser'] !== undefined) {
    body = parameters['unitUser']
  }
  if (parameters['unitUser'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: unitUser'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateWhistleblowerUnitUserUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/users/update'
}
export const updateWhistleblowerUnitUserUsingPOST_TYPE = function() {
  return 'post'
}
export const updateWhistleblowerUnitUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/users/update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * validateLongFormatUserName
 * request: validateLongFormatUserNameUsingPOST
 * url: validateLongFormatUserNameUsingPOSTURL
 * method: validateLongFormatUserNameUsingPOST_TYPE
 * raw_url: validateLongFormatUserNameUsingPOST_RAW_URL
 * @param userSuggestionDto - userSuggestionDto
 */
export const validateLongFormatUserNameUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/users/validate-username/long'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userSuggestionDto'] !== undefined) {
    body = parameters['userSuggestionDto']
  }
  if (parameters['userSuggestionDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userSuggestionDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const validateLongFormatUserNameUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/users/validate-username/long'
}
export const validateLongFormatUserNameUsingPOST_TYPE = function() {
  return 'post'
}
export const validateLongFormatUserNameUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/users/validate-username/long'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * validateShortFormatUserName
 * request: validateShortFormatUserNameUsingPOST
 * url: validateShortFormatUserNameUsingPOSTURL
 * method: validateShortFormatUserNameUsingPOST_TYPE
 * raw_url: validateShortFormatUserNameUsingPOST_RAW_URL
 * @param userSuggestionDto - userSuggestionDto
 */
export const validateShortFormatUserNameUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/users/validate-username/short'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userSuggestionDto'] !== undefined) {
    body = parameters['userSuggestionDto']
  }
  if (parameters['userSuggestionDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userSuggestionDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const validateShortFormatUserNameUsingPOST_RAW_URL = function() {
  return '/api/private/wbunit/users/validate-username/short'
}
export const validateShortFormatUserNameUsingPOST_TYPE = function() {
  return 'post'
}
export const validateShortFormatUserNameUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/users/validate-username/short'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * streamVideo
 * request: streamVideoUsingGET
 * url: streamVideoUsingGETURL
 * method: streamVideoUsingGET_TYPE
 * raw_url: streamVideoUsingGET_RAW_URL
 * @param uuid - uuid
 */
export const streamVideoUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/private/wbunit/video/{uuid}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters['uuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const streamVideoUsingGET_RAW_URL = function() {
  return '/api/private/wbunit/video/{uuid}'
}
export const streamVideoUsingGET_TYPE = function() {
  return 'get'
}
export const streamVideoUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/private/wbunit/video/{uuid}'
  path = path.replace('{uuid}', `${parameters['uuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCompanyInfo
 * request: getCompanyInfoUsingGET
 * url: getCompanyInfoUsingGETURL
 * method: getCompanyInfoUsingGET_TYPE
 * raw_url: getCompanyInfoUsingGET_RAW_URL
 */
export const getCompanyInfoUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/public/case/company-info'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCompanyInfoUsingGET_RAW_URL = function() {
  return '/api/public/case/company-info'
}
export const getCompanyInfoUsingGET_TYPE = function() {
  return 'get'
}
export const getCompanyInfoUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/public/case/company-info'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * newCase
 * request: newCaseUsingPOST
 * url: newCaseUsingPOSTURL
 * method: newCaseUsingPOST_TYPE
 * raw_url: newCaseUsingPOST_RAW_URL
 * @param caseDto - caseDto
 * @param files - files
 */
export const newCaseUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/public/case/new/by-form'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['caseDto'] !== undefined) {
    form['caseDto'] = parameters['caseDto']
  }
  if (parameters['caseDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: caseDto'))
  }
  if (parameters['files'] !== undefined) {
    form['files'] = parameters['files']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const newCaseUsingPOST_RAW_URL = function() {
  return '/api/public/case/new/by-form'
}
export const newCaseUsingPOST_TYPE = function() {
  return 'post'
}
export const newCaseUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/public/case/new/by-form'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * newCaseByMeeting
 * request: newCaseByMeetingUsingPOST
 * url: newCaseByMeetingUsingPOSTURL
 * method: newCaseByMeetingUsingPOST_TYPE
 * raw_url: newCaseByMeetingUsingPOST_RAW_URL
 * @param submissionByMeetingDto - submissionByMeetingDto
 */
export const newCaseByMeetingUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/public/case/new/by-meeting'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['submissionByMeetingDto'] !== undefined) {
    body = parameters['submissionByMeetingDto']
  }
  if (parameters['submissionByMeetingDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: submissionByMeetingDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const newCaseByMeetingUsingPOST_RAW_URL = function() {
  return '/api/public/case/new/by-meeting'
}
export const newCaseByMeetingUsingPOST_TYPE = function() {
  return 'post'
}
export const newCaseByMeetingUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/public/case/new/by-meeting'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * newCaseByPhone
 * request: newCaseByPhoneUsingPOST
 * url: newCaseByPhoneUsingPOSTURL
 * method: newCaseByPhoneUsingPOST_TYPE
 * raw_url: newCaseByPhoneUsingPOST_RAW_URL
 * @param submissionByPhoneDto - submissionByPhoneDto
 */
export const newCaseByPhoneUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/public/case/new/by-phone'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['submissionByPhoneDto'] !== undefined) {
    body = parameters['submissionByPhoneDto']
  }
  if (parameters['submissionByPhoneDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: submissionByPhoneDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const newCaseByPhoneUsingPOST_RAW_URL = function() {
  return '/api/public/case/new/by-phone'
}
export const newCaseByPhoneUsingPOST_TYPE = function() {
  return 'post'
}
export const newCaseByPhoneUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/public/case/new/by-phone'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getProtectionOfficerLogo
 * request: getProtectionOfficerLogoUsingGET
 * url: getProtectionOfficerLogoUsingGETURL
 * method: getProtectionOfficerLogoUsingGET_TYPE
 * raw_url: getProtectionOfficerLogoUsingGET_RAW_URL
 * @param poUuid - po-uuid
 */
export const getProtectionOfficerLogoUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/public/case/protection-officer/{po-uuid}/logo'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{po-uuid}', `${parameters['poUuid']}`)
  if (parameters['poUuid'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: poUuid'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProtectionOfficerLogoUsingGET_RAW_URL = function() {
  return '/api/public/case/protection-officer/{po-uuid}/logo'
}
export const getProtectionOfficerLogoUsingGET_TYPE = function() {
  return 'get'
}
export const getProtectionOfficerLogoUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/public/case/protection-officer/{po-uuid}/logo'
  path = path.replace('{po-uuid}', `${parameters['poUuid']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * importReseller
 * request: importResellerUsingGET
 * url: importResellerUsingGETURL
 * method: importResellerUsingGET_TYPE
 * raw_url: importResellerUsingGET_RAW_URL
 */
export const importResellerUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/public/reseller/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const importResellerUsingGET_RAW_URL = function() {
  return '/api/public/reseller/import'
}
export const importResellerUsingGET_TYPE = function() {
  return 'get'
}
export const importResellerUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/public/reseller/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSiteLookAndFeel
 * request: getSiteLookAndFeelUsingGET
 * url: getSiteLookAndFeelUsingGETURL
 * method: getSiteLookAndFeelUsingGET_TYPE
 * raw_url: getSiteLookAndFeelUsingGET_RAW_URL
 * @param languageCode - languageCode
 */
export const getSiteLookAndFeelUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/public/site-look-and-feel/language/{languageCode}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{languageCode}', `${parameters['languageCode']}`)
  if (parameters['languageCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: languageCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSiteLookAndFeelUsingGET_RAW_URL = function() {
  return '/api/public/site-look-and-feel/language/{languageCode}'
}
export const getSiteLookAndFeelUsingGET_TYPE = function() {
  return 'get'
}
export const getSiteLookAndFeelUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/public/site-look-and-feel/language/{languageCode}'
  path = path.replace('{languageCode}', `${parameters['languageCode']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSiteLookAndFeelLogo
 * request: getSiteLookAndFeelLogoUsingGET
 * url: getSiteLookAndFeelLogoUsingGETURL
 * method: getSiteLookAndFeelLogoUsingGET_TYPE
 * raw_url: getSiteLookAndFeelLogoUsingGET_RAW_URL
 * @param logoFileName - logoFileName
 */
export const getSiteLookAndFeelLogoUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/api/public/site-look-and-feel/logo/{logoFileName}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{logoFileName}', `${parameters['logoFileName']}`)
  if (parameters['logoFileName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: logoFileName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSiteLookAndFeelLogoUsingGET_RAW_URL = function() {
  return '/api/public/site-look-and-feel/logo/{logoFileName}'
}
export const getSiteLookAndFeelLogoUsingGET_TYPE = function() {
  return 'get'
}
export const getSiteLookAndFeelLogoUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/api/public/site-look-and-feel/logo/{logoFileName}'
  path = path.replace('{logoFileName}', `${parameters['logoFileName']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingDELETE
 * url: errorHtmlUsingDELETEURL
 * method: errorHtmlUsingDELETE_TYPE
 * raw_url: errorHtmlUsingDELETE_RAW_URL
 */
export const errorHtmlUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingDELETE_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingDELETE_TYPE = function() {
  return 'delete'
}
export const errorHtmlUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingGET
 * url: errorHtmlUsingGETURL
 * method: errorHtmlUsingGET_TYPE
 * raw_url: errorHtmlUsingGET_RAW_URL
 */
export const errorHtmlUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingGET_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingGET_TYPE = function() {
  return 'get'
}
export const errorHtmlUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingHEAD
 * url: errorHtmlUsingHEADURL
 * method: errorHtmlUsingHEAD_TYPE
 * raw_url: errorHtmlUsingHEAD_RAW_URL
 */
export const errorHtmlUsingHEAD = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('head', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingHEAD_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingHEAD_TYPE = function() {
  return 'head'
}
export const errorHtmlUsingHEADURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingOPTIONS
 * url: errorHtmlUsingOPTIONSURL
 * method: errorHtmlUsingOPTIONS_TYPE
 * raw_url: errorHtmlUsingOPTIONS_RAW_URL
 */
export const errorHtmlUsingOPTIONS = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('options', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingOPTIONS_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingOPTIONS_TYPE = function() {
  return 'options'
}
export const errorHtmlUsingOPTIONSURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPATCH
 * url: errorHtmlUsingPATCHURL
 * method: errorHtmlUsingPATCH_TYPE
 * raw_url: errorHtmlUsingPATCH_RAW_URL
 */
export const errorHtmlUsingPATCH = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPATCH_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPATCH_TYPE = function() {
  return 'patch'
}
export const errorHtmlUsingPATCHURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPOST
 * url: errorHtmlUsingPOSTURL
 * method: errorHtmlUsingPOST_TYPE
 * raw_url: errorHtmlUsingPOST_RAW_URL
 */
export const errorHtmlUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPOST_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPOST_TYPE = function() {
  return 'post'
}
export const errorHtmlUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPUT
 * url: errorHtmlUsingPUTURL
 * method: errorHtmlUsingPUT_TYPE
 * raw_url: errorHtmlUsingPUT_RAW_URL
 */
export const errorHtmlUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPUT_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPUT_TYPE = function() {
  return 'put'
}
export const errorHtmlUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}