export const managerModel = {
  principal: null,
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  password: null,
  name: null,
};
