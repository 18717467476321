import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      fontFamily: 'Helvetica Neue',
      light: {
        primary: '#172A55',
        lightPrimary: '#E5F1FC',
        secondary: '#1F467B',
        info: '#2196F3',
        error: '#A30404',
        accent: '#82B1FF',
        gray: '#EBF0F4',
        cBlack: '#414243',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#ECF1F5',
      }
    }
  },
});
